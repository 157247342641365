import {Badge, Breadcrumb, Descriptions, message, Row} from "antd";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import ButtonKeyEdit from "../components/ButtonKeyEdit";
import CentreBreadcrumb from "../components/CentreBreadcrumb";
import CentreLarge from "../components/CentreLarge";
import TextLarge from "../components/TextLarge";
import { withStore } from "../components/StoreProvider";
import { makeGet } from "../modules/api-client";
import CenteredLoader from "../components/CenteredLoader";

class Key extends React.Component {
  state = {
    key: {},
    loaded: false,
  };

  componentDidMount() {
    const { organisationId, keyId } = this.props.match.params;

    message.loading("Key loading...", 0.5);

    makeGet(`/organisations/${ organisationId }/keys/${ keyId }`)
      .then((res) => {
        message.success("Key loaded");
        let data = res.body;
        this.setState({ key: data, loaded: true });
      })
      .catch((err) => {
        if (err.response.status === 404) {
          message.error("Key not found");
        } else {
          message.error("User not authorised");
        }
        this.props.history.push(`/organisations/${organisationId}`);
      });
  }

  render() {
    const { key, loaded } = this.state;
    const { authUser } = this.props.store;
    console.log(key);

    return (
      <div>
        <CentreBreadcrumb>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={"/"}>
                Home
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={"/organisations"}> All Organisations </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/organisations/${key.organisationId}`}>
                {" "}
                Organisation{" "}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/organisations/${key.organisationId}/keys`}>
                {" "}
                All Keys{" "}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <strong> {key.kid} </strong>
            </Breadcrumb.Item>
          </Breadcrumb>
        </CentreBreadcrumb>

        {!loaded && (
            <CenteredLoader/>
        )}

        {loaded && (
          <CentreLarge title="Key">
            {authUser.role === "Admin" || key.organisationId === authUser.organisationId ? (
              <ButtonKeyEdit
                organisationId={key.organisationId}
                keyId={key.kid}
              />
            ) : null}
            <TextLarge>
              <p>Here are the details for Key Id {key.kid}</p>
            </TextLarge>
            {key.kid && (
              <Descriptions bordered>
                <Descriptions.Item label="Key Id" span={3}>
                  {key.kid}
                </Descriptions.Item>
                <Descriptions.Item label="Organisation Id" span={3}>
                  {key.organisationId}
                </Descriptions.Item>
                <Descriptions.Item label="Environment" span={3}>
                  {key.environment}
                </Descriptions.Item>
                <Descriptions.Item label="Key Name" span={3}>
                  {key.name}
                </Descriptions.Item>
                <Descriptions.Item label="Key Type" span={3}>
                  {key.kty}
                </Descriptions.Item>
                <Descriptions.Item label="Key Use" span={3}>
                  {key.use}
                </Descriptions.Item>

                {key?.e && (
                  <Descriptions.Item label="E" span={3}>
                    {key.e}
                  </Descriptions.Item>
                )}

                {key?.n && (
                  <Descriptions.Item label="N" span={3}>
                    <p className="break">{key.n}</p>
                  </Descriptions.Item>
                )}

                {key?.crv && (
                  <Descriptions.Item label="crv" span={3}>
                    <p className="break">{key.crv}</p>
                  </Descriptions.Item>
                )}

                {key?.x && (
                  <Descriptions.Item label="X" span={3}>
                    <p className="break">{key.x}</p>
                  </Descriptions.Item>
                )}

                {key?.y && (
                  <Descriptions.Item label="Y" span={3}>
                    <p className="break">{key.y}</p>
                  </Descriptions.Item>
                )}


                <Descriptions.Item label="Certificate URL" span={3}>
                  <a href={key.x5u}>{key.x5u}</a>
                </Descriptions.Item>
                <Descriptions.Item label="Created Date" span={3}>
                  {moment(key.createdDate).format("YYYY-MM-DD HH:mm:ss")}
                </Descriptions.Item>
                <Descriptions.Item label="Revoked Date" span={3}>
                  {key.revokedDate &&
                    moment(key.revokedDate).format("YYYY-MM-DD HH:mm:ss")}
                </Descriptions.Item>
                <Descriptions.Item label="Status" span={3}>
                  {key.status === "Active" ? (
                    <div>
                      {key.status} <Badge status="success" />
                    </div>
                  ) : (
                    <div>
                      {key.status} <Badge status="error" />
                    </div>
                  )}
                </Descriptions.Item>
              </Descriptions>
            )}
          </CentreLarge>
        )}
      </div>
    );
  }
}

export default withStore(Key);
