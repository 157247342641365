import React from "react";

const Terms = () => (
  <div
    style={{
      lineHeight: "175%",
      paddingTop: "3px",
      paddingBottom: "3px",
      letterSpacing: "0.2px",
    }}
  >
    <h2>API Centre Register</h2>
    <p>
      The API Centre Register ("the Register") is maintained by the API Centre
      and is provided to API Standards Users subject to the API Centre terms and
      conditions and these Terms of Use ("the Terms") set out below.
    </p>
    <h2>Description</h2>
    <p>
      The Register is a central service that enables the automated exchange of
      data (e.g., certificates and server locations) between API Standards
      Users. This data is required for API Standards Users to securely connect
      to each other using the security measures prescribed by the API Standards.
    </p>
    <h2>Terms of Use</h2>
    <p>
      Each Registered API Standards User is entitled to set up an API Centre
      Register Account User (“Account User”). Each Account User agrees that:
    </p>
    <ol type="1">
      <li>
        its Account User log-in credentials are not shared with other people.{" "}
      </li>
      <li>
        it does not attempt to gain unauthorised access to any part of the
        Register, the server on which the Register resides, or any other server,
        computer, or database connected to the API Centre or Payments NZ.
      </li>
      <li>
        its use of the Register does not expose the API Centre or Payments NZ or
        other API Standards Users to malicious software or security risks such
        as malware, Trojans, viruses, denial of service attacks or any other
        material that is malicious or technologically harmful to the Register.
      </li>
      <li>
        its use of the Register complies with all applicable laws and
        regulations, including copyright and privacy laws.
      </li>
      <li>
        its use of the Register is professional and respectful of other users of
        the Register.
      </li>
      <li>
        Payments NZ will collect and hold information about technical contacts
        for API Standards Users to enable it to:
      </li>
      <ol type="a">
        <li>maintain the Register; and</li>
        <li>provide aggregated reporting of Register use.</li>
      </ol>
      <li>
        Payments NZ is not liable for any loss whether the loss is direct or
        consequential (including loss of profits), incurred by you arising out
        of, or in connection with, the use of the Register.
      </li>
      <li>Payments NZ may make changes to the Register from time to time.</li>
      <li>
        Payments NZ may suspend or terminate your Register account if you breach
        these Terms or if your Register account is inactive for more than twelve
        months.
      </li>
      <li>
        Payments NZ may amend these Terms from time to time by publishing
        amended Terms on the API Centre Register Portal and notifying API
        Standards Users, using the contact details provided. By continuing to
        access and use the Register you are deemed to accept the amended Terms.
      </li>
    </ol>
    <h2>Indemnity</h2>
    <p>
      You agree to indemnify and hold Payments NZ, the API Centre, the API
      Council, the API Independent Committee, the Board and Chief Executive of
      Payments NZ and the officers, employees and agents of Payments NZ or the
      API Centre from all claims, liabilities, damages, losses and expenses
      suffered or incurred by any third party due to or arising out of your
      breach of these Terms, or your violation of any law or the rights of a
      third party relating to your use of the Register.
    </p>
  </div>
);

export default Terms;
