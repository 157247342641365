import { Breadcrumb, Button, Form, Input, message } from "antd";
import React from "react";
import { Link, withRouter } from "react-router-dom";
import CentreBreadcrumb from "../components/CentreBreadcrumb";
import CentreLarge from "../components/CentreLarge";
import TextSmall from "../components/TextSmall";
import { withStore } from "../components/StoreProvider";
import { makePost } from "../modules/api-client";

class UserAddForm extends React.Component {
  state = {
    data: {},
    submitting: false,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let { data } = this.state;
    const { organisationId } = this.props.match.params;

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ submitting: true });

        var updatedData = Object.assign({}, data, values);
        for (var key in updatedData) {
          if (
            typeof updatedData[key] === "string" ||
            updatedData[key] instanceof String
          ) {
            updatedData[key] = updatedData[key].toString().trim();
          }
        }
        this.setState({ data: updatedData });

        makePost(
            `/organisations/${organisationId}/users`,
            { data: updatedData },
        )
        .then((res) => {
          message.success("User added");
          this.props.history.push(`/organisations/${ organisationId }/users/${ res.body.data.userId }`);
        })
        .catch((err) => {
          this.setState({ submitting: false });
          message.error(`Error: ${ err.response.body.data.error }`);
        });
      }
    });
  };

  validateEmail = (rule, value, callback) => {
    if (value === null || value.length === 0) {
      callback();
      return;
    } else if (
      value.length < 255 &&
      value.substring(value.indexOf("@")).indexOf(".") > 0 &&
      value.substring(value.length - 1) !== "."
    ) {
      callback();
      return;
    }
    callback("Must be a valid email");
  };

  render() {
    const { getFieldDecorator, isFieldTouched } = this.props.form;
    const { organisationId } = this.props.match.params;
    const { submitting } = this.state;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 9 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 0,
        },
      },
    };

    return (
      <div>
        <CentreBreadcrumb>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={"/"}>
                Home
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={"/organisations"}> All Organisations </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/organisations/${organisationId}`}>
                {" "}
                Organisation{" "}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/organisations/${organisationId}/users`}>
                {" "}
                All Users{" "}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <strong> Add </strong>
            </Breadcrumb.Item>
          </Breadcrumb>
        </CentreBreadcrumb>

        <CentreLarge title="User (Add)">
          <TextSmall>
            Add a user for the organisation to the API Centre Register.
          </TextSmall>

          <Form onSubmit={this.handleSubmit} style={{ textAlign: "left" }}>
            <Form.Item {...formItemLayout} label="First Name">
              {getFieldDecorator("firstName", {
                rules: [
                  { required: true, message: "First name cannot be blank" },
                ],
              })(<Input
                  data-cy="user-first-name"
                  placeholder="User first name"
                  disabled={this.state.submitting}
              />)}
            </Form.Item>

            <Form.Item {...formItemLayout} label="Last Name">
              {getFieldDecorator("lastName", {
                rules: [
                  { required: true, message: "Last name cannot be blank" },
                ],
              })(<Input
                  data-cy="user-last-name"
                  placeholder="User last name"
                  disabled={this.state.submitting}
              />)}
            </Form.Item>

            <Form.Item {...formItemLayout} label="Email">
              {getFieldDecorator("email", {
                rules: [
                  { required: true, message: "Email cannot be blank" },
                  { validator: this.validateEmail },
                ],
              })(
                <Input
                  placeholder="User email"
                  data-cy="user-email"
                  disabled={this.state.submitting}
                />
              )}
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
              <Button
                data-cy="user-add-submit-btn"
                className="btn btn--edit"
                type="primary"
                htmlType="submit"
                loading={submitting}
                disabled={
                  !(
                    isFieldTouched("firstName") ||
                    isFieldTouched("lastName") ||
                    isFieldTouched("email")
                  )
                }
              >
                Add
              </Button>
            </Form.Item>
          </Form>
        </CentreLarge>
      </div>
    );
  }
}

let UserAdd = Form.create({ name: "userAdd" })(UserAddForm);

UserAdd = withRouter(UserAdd);
UserAdd = withStore(UserAdd);

export default UserAdd;
