import { Col, Row } from "antd";
import React from "react";

const CentreLarge = ({ children, title }) => (
  <div className="content">
    <Row gutter={2}>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <h1 className="h1">{title}</h1>
        {children}
      </Col>
    </Row>
  </div>
);

export default CentreLarge;
