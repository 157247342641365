import React from "react";
import { Form } from "antd";
import { withRouter } from "react-router-dom";
import { withStore } from "../components/StoreProvider";
import { makeGet } from "../modules/api-client";
import CenteredLoader from "../components/CenteredLoader";
import Cookies from 'js-cookie'


class AzureLoginForm extends React.Component {
   state = {
    loading: true,
  };


  componentDidMount() {
    const { search } = this.props.location;
    const { authUser } = this.props.store;

    let next = "/organisations";

    if (search) {
      next = search.split("?next=")[1];
    }
    this.setState({ next: next });

    // user is already authenticated so no need to login again.
    if (authUser) {
      this.props.history.push(next);
      return;
    }

    Cookies.remove('csrftoken', { path: '' }) // removed!
    Cookies.remove('sessionid', { path: '' }) // removed!

    makeGet("/users/login-azureb2c").then(response => {
      const {redirect_url} = response.body;
      this.setState({ loading: false });
      window.location.href = redirect_url;
    })
  }
  
  render() {

    const { loading } = this.state;

    if(loading){
      return (
          <CenteredLoader/>
      )
    }
    return null;
  }

}


let Login;

Login = Form.create({name: "login"})(AzureLoginForm);  // Use Azure login form for everthing else
Login = withRouter(Login);
Login = withStore(Login);

export default Login;
