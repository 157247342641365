import React from "react";
import {makeGet} from "../modules/api-client";

class ChangePassword extends React.Component {
  componentDidMount = () => {
    makeGet(
        `/users/change-password-azureb2c`,
    )
    .then((result) => {
      console.log(result)
      if(result.status === 200){
        console.log(result.body.change_password_url)
        window.location.href = result.body.change_password_url;
      }else{
        console.error("Unable to get reset password url")
      }
    })   
  }

  render() {
    return null;
  }
}

export default ChangePassword;
