import {Badge, Breadcrumb, Button, Descriptions, Divider, Icon, message, Row, Table, Tag, Tooltip} from "antd";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import ButtonAuthServerAdd from "../components/ButtonAuthServerAdd";
import ButtonKeyAdd from "../components/ButtonKeyAdd";
import ButtonOrganisationEdit from "../components/ButtonOrganisationEdit";
import ButtonUserAdd from "../components/ButtonUserAdd";
import CentreBreadcrumb from "../components/CentreBreadcrumb";
import CentreLarge from "../components/CentreLarge";
import TextLarge from "../components/TextLarge";
import { withStore } from "../components/StoreProvider";
import { makeGet } from "../modules/api-client";
import CenteredLoader from "../components/CenteredLoader";


class Organisation extends React.Component {
  state = {
    organisation: {},
    users: [],
    userLoading: true,
    userExpanded: false,
    keys: [],
    paginationActive: { position: "top" },
    paginationRevoked: { position: "top" },
    keyLoading: true,
    keyExpanded: false,
    authServers: [],
    authServerLoading: true,
    authServerExpanded: false,
    loaded: false,
    generating: false
  };

  componentDidMount() {
    const { organisationId } = this.props.match.params;
    const { authUser } = this.props.store;

    message.loading("Organisation loading...", 0.5);

    makeGet(`/organisations/${ organisationId }`)
      .then((res) => {
        message.success("Organisation loaded");
        let data = res.body.data;
        this.setState({ organisation: data, loaded: true });
        if (
          data.types.filter((type) => type === "API Provider")[0] ===
          "API Provider"
        ) {
          makeGet(`/organisations/${ organisationId }/auth-servers`)
            .then((res) => {
              let data = res.body.data;
              this.setState({ authServers: data, authServerLoading: false });
            })
            .catch(() => {
              this.setState({ authServers: [], authServerLoading: false });
            });
        }
      })
      .catch((err) => {
        if (err.response.status === 404) {
          message.error("Organisation not found");
        } else {
          message.error("User not authorised");
        }
        this.props.history.push("/organisations");
      });

    makeGet(`/organisations/${organisationId}/keys`)
      .then((res) => {
        let { paginationActive, paginationRevoked } = this.state;
        let data = res.body.keys;
        paginationActive.total = data.filter(
          (key) => key.status === "Active"
        ).length;
        paginationRevoked.total = data.filter(
          (key) => key.status === "Revoked"
        ).length;
        this.setState({
          keys: data,
          paginationActive: paginationActive,
          paginationRevoked: paginationRevoked,
          keyLoading: false,
        });
      })
      .catch(() => {
        this.setState({ keys: [], keyLoading: false });
      });

    if (authUser.role === "Admin" || authUser.organisationId === organisationId) {
      makeGet(`/organisations/${ organisationId }/users`)
        .then((res) => {
          let data = res.body.data;
          this.setState({ users: data, userLoading: false });
        })
        .catch(() => {
          this.setState({ users: [], userLoading: false });
        });
    }
  }

  componentWillReceiveProps(newProps) {
    const { organisationId } = newProps.match.params;
    const { authUser, organisation } = this.state;

    if (organisationId !== organisation.organisationId) {
      message.loading("Organisation loading...", 0.5);
      this.setState({ loaded: false });

      makeGet(`/organisations/${ organisationId }`)
        .then((res) => {
          message.success("Organisation loaded");
          let data = res.body.data;
          this.setState({ organisation: data, loaded: true });
          if (
            data.types.filter((type) => type === "API Provider")[0] ===
            "API Provider"
          ) {
            makeGet(`/organisations/${ organisationId }/auth-servers`)
              .then((res) => {
                let data = res.body.data;
                this.setState({ authServers: data, authServerLoading: false });
              })
              .catch(() => {
                this.setState({ authServers: [], authServerLoading: false });
              });
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            message.error("Organisation not found");
          } else {
            message.error("User not authorised");
          }
          this.props.history.push("/organisations");
        });

      makeGet(`/organisations/${ organisationId }/keys`)
        .then((res) => {
          let { paginationActive, paginationRevoked } = this.state;
          let data = res.body.keys;
          paginationActive.total = data.filter(
            (key) => key.status === "Active"
          ).length;
          paginationRevoked.total = data.filter(
            (key) => key.status === "Revoked"
          ).length;
          this.setState({
            keys: data,
            paginationActive: paginationActive,
            paginationRevoked: paginationRevoked,
            keyLoading: false,
          });
        })
        .catch(() => {
          this.setState({ keys: [], keyLoading: false });
        });

      if (authUser.role === "Admin" || authUser.organisationId === organisationId) {
        makeGet(`/organisations/${ organisationId }/users`)
          .then((res) => {
            let data = res.body.data;
            this.setState({ users: data, userLoading: false });
          })
          .catch(() => {
            this.setState({ users: [], userLoading: false });
          });
      }
    }
  }

  handleGenerate = () => {
    const { organisationId } = this.props.match.params;
    const { organisation } = this.state;
    this.setState({generating: true})
    makeGet(`/organisations/${ organisationId }/apiKey`)
      .then((res) => {
        message.success("New API key generated");

        let updatedData = organisation;
        updatedData.apiKey = res.body.data.apiKey;
        this.setState({ organisation: updatedData, generating: false });
      })
      .catch(() => {
        message.error("Try again");
        this.setState({ generating: false });
      });
  };

  handleCopy = async () => {
    // NOTE: navigator.clipboard.writeText requires a secure context (https). This won't work in development only in production.
    // if in doubt use window.isSecureContext to check the environment
    if (window.isSecureContext) {
      const { organisation } = this.state;
      await navigator.clipboard.writeText(organisation.apiKey);
      message.success("Key copied to clipboard");
    } else {
      message.warning("Sorry, we are unable to paste to the clipboard. Please copy the api key directly from the browser text");
    }
  };

  handleTableChangeActive = (pagination, filters, sorter) => {
    const pager = { ...this.state.paginationActive };
    pager.current = pagination.current;
    this.setState({ paginationActive: pager });
  };

  handleTableChangeRevoked = (pagination, filters, sorter) => {
    const pager = { ...this.state.paginationRevoked };
    pager.current = pagination.current;
    this.setState({ paginationRevoked: pager });
  };

  handleUserExpand = () => {
    let { userExpanded } = this.state;
    this.setState({ userExpanded: userExpanded === false });
  };

  handleKeyExpand = () => {
    let { keyExpanded } = this.state;
    this.setState({ keyExpanded: keyExpanded === false });
  };

  handleAuthServerExpand = () => {
    let { authServerExpanded } = this.state;
    this.setState({ authServerExpanded: authServerExpanded === false });
  };

  render() {
    const {
      organisation,
      users,
      userLoading,
      userExpanded,
      keys,
      paginationActive,
      paginationRevoked,
      keyLoading,
      keyExpanded,
      authServers,
      authServerLoading,
      authServerExpanded,
      loaded,
      generating,
    } = this.state;
    const { authUser } = this.props.store;

    const userColumns = [
      {
        title: () => (
          <Tooltip title="Double click on a row to view details">
            First Name
          </Tooltip>
        ),
        dataIndex: "firstName",
        key: "firstName",
        sorter: (a, b) => a.firstName.localeCompare(b.firstName),
        defaultSortOrder: "ascend",
        width: "15%",
      },
      {
        title: () => (
          <Tooltip title="Double click on a row to view details">
            Last Name
          </Tooltip>
        ),
        dataIndex: "lastName",
        key: "lastName",
        sorter: (a, b) => a.lastName.localeCompare(b.lastName),
        width: "15%",
      },
      { title: "Email", dataIndex: "email", key: "email", width: "25%" },
      {
        title: "Created",
        render: (record) => (
          <span>{moment(record.createdDate).format("YYYY-MM-DD")}</span>
        ),
        sorter: (a, b) =>
          moment(a.createdDate).unix() - moment(b.createdDate).unix(),
        width: "15%",
      },
      { title: "Status", dataIndex: "status", key: "status", width: "15%" },
      {
        title: "Actions",
        dataIndex: "",
        key: "x",
        align: "center",
        width: "15%",
        render: (record) => (
          <span>
            <Link
              to={`/organisations/${record.organisationId}/users/${record.userId}`}
            >
              View
            </Link>
            <Divider type="vertical" />
            {authUser.role === "Admin" || authUser.organisationId === record.organisationId ? (
              <Link
                to={`/organisations/${record.organisationId}/users/${record.userId}/edit`}
              >
                Edit
              </Link>
            ) : (
              <span style={{ color: "rgba(0,0,0,.25)" }}>Edit</span>
            )}
          </span>
        ),
      },
    ];

    const keyColumns = [
      {
        title: () => (
          <Tooltip title="Double click on a row to view details">
            Environment
          </Tooltip>
        ),
        dataIndex: "environment",
        key: "environment",
        sorter: (a, b) => a.environment.localeCompare(b.environment),
        width: "25%",
      },
      { title: "Use", dataIndex: "use", key: "use", width: "15%" },
      { title: "Key Type", dataIndex: "kty", key: "kty", width: "15%" },
      {
        title: "Created",
        render: (record) => (
          <span>{moment(record.createdDate).format("YYYY-MM-DD")}</span>
        ),
        sorter: (a, b) =>
          moment(a.createdDate).unix() - moment(b.createdDate).unix(),
        defaultSortOrder: "descend",
        width: "15%",
      },
      { title: "Status", dataIndex: "status", key: "status", width: "15%" },
      {
        title: "Actions",
        dataIndex: "",
        key: "x",
        align: "center",
        width: "15%",
        render: (record) => (
          <span>
            <Link
              to={`/organisations/${record.organisationId}/keys/${record.kid}`}
            >
              View
            </Link>
            <Divider type="vertical" />
            {authUser.role === "Admin" || record.organisationId === authUser.organisationId ? (
              <Link
                to={`/organisations/${record.organisationId}/keys/${record.kid}/edit`}
              >
                Edit
              </Link>
            ) : (
              <span style={{ color: "rgba(0,0,0,.25)" }}>Edit</span>
            )}
          </span>
        ),
      },
    ];

    const authServerColumns = [
      {
        title: () => (
          <Tooltip title="Double click on a row to view details">
            Environment
          </Tooltip>
        ),
        dataIndex: "environment",
        key: "environment",
        sorter: (a, b) => a.environment.localeCompare(b.environment),
        width: "25%",
      },
      { title: "Name", dataIndex: "name", key: "name", width: "30%" },
      {
        title: "Created",
        render: (record) => (
          <span>{moment(record.createdDate).format("YYYY-MM-DD")}</span>
        ),
        sorter: (a, b) =>
          moment(a.createdDate).unix() - moment(b.createdDate).unix(),
        defaultSortOrder: "descend",
        width: "20%",
      },
      { title: "Status", dataIndex: "status", key: "status", width: "10%" },
      {
        title: "Actions",
        dataIndex: "",
        key: "x",
        align: "center",
        width: "15%",
        render: (record) => (
          <span>
            <Link
              to={`/organisations/${record.organisationId}/auth-servers/${record.authServerId}`}
            >
              View
            </Link>
            <Divider type="vertical" />
            {record.organisationId === authUser.organisationId ? (
              <Link
                to={`/organisations/${record.organisationId}/auth-servers/${record.authServerId}/edit`}
              >
                Edit
              </Link>
            ) : (
              <span style={{ color: "rgba(0,0,0,.25)" }}>Edit</span>
            )}
          </span>
        ),
      },
    ];

    return (
      <div>
        <CentreBreadcrumb>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={"/"}>
                Home
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={"/organisations"}> All Organisations </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <strong> Organisation </strong>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/organisations/${organisation.organisationId}/keys`}>
                {" "}
                All Keys{" "}
              </Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </CentreBreadcrumb>
        {!loaded && (
            <CenteredLoader/>
        )}

        {loaded && (
            <CentreLarge title="Organisation">
              {authUser.role === "Admin" ? (
                  <ButtonOrganisationEdit
                      organisationId={organisation.organisationId}
                  />
              ) : null}
              <TextLarge>
              <p>
                Here are API Centre Register details for{" "}
                <strong>{organisation.organisationName}</strong>
              </p>
              <p>
                If you have appropriate access you will be able to setup and
                maintain certificates and authorisation server information.
              </p>
            </TextLarge>
            <h2 className="h2">{organisation.organisationName}</h2>
            {organisation.organisationId && (
              <Descriptions bordered>
                <Descriptions.Item label="Name" span={3}>
                  {organisation.organisationName}
                </Descriptions.Item>
                <Descriptions.Item label="NZBN" span={3}>
                  {organisation.NZBN}
                </Descriptions.Item>
                <Descriptions.Item label="Organisation Id" span={3}>
                  {organisation.organisationId}
                </Descriptions.Item>
                <Descriptions.Item label="Type" span={3}>
                  {organisation.types.map((type) => (
                    <Tag key={type}>{type}</Tag>
                  ))}
                </Descriptions.Item>
                <Descriptions.Item label="Created Date" span={3}>
                  {moment(organisation.createdDate).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )}
                </Descriptions.Item>
                {authUser.organisationId === organisation.organisationId ? (
                  <Descriptions.Item label="API Key" span={3}>
                    <p className="break">{organisation.apiKey}</p>
                    <span>
                      <Button className="btn btn--edit" onClick={this.handleCopy.bind(this)}>
                        Copy Key
                      </Button>{" "}
                      <Button
                          data-cy="organisation-generate-new-key-btn"
                          className="btn btn--edit btn--secondary"
                          onClick={this.handleGenerate}
                          loading={generating}
                      >
                        Generate New Key
                      </Button>
                    </span>
                  </Descriptions.Item>
                ) : null}
                <Descriptions.Item label="Status" span={3}>
                  {organisation.status === "Active" ? (
                    <div>
                      {organisation.status} <Badge status="success" />
                    </div>
                  ) : (
                    <div>
                      {organisation.status} <Badge status="error" />
                    </div>
                  )}
                </Descriptions.Item>
              </Descriptions>
            )}
            {(authUser.role === "Admin" || users.length > 0) && (
              <div>
                <div style={{ minHeight: "6vh" }}></div>

                <h2 className="h2">Users</h2>

                {authUser.role === "Admin" ? (
                  <ButtonUserAdd organisationId={organisation.organisationId} />
                ) : null}

                <TextLarge>
                  <p>
                    Listed below are all users for{" "}
                    <strong>{organisation.organisationName}</strong>
                  </p>
                  <p>
                    You can click through to view user information and maintain
                    user status.
                  </p>
                </TextLarge>
                <Table
                  scroll={{ x: 800 }}
                  pagination={false}
                  loading={userLoading}
                  dataSource={users.filter((user) => user.status === "Active")}
                  columns={userColumns}
                  onRow={(record, rowIndex) => {
                    return {
                      onDoubleClick: () => {
                        this.props.history.push(
                          `/organisations/${record.organisationId}/users/${record.userId}`
                        );
                      },
                    };
                  }}
                />

                <div
                  onClick={() => this.handleUserExpand()}
                  style={{ paddingTop: "48px", paddingBottom: "24px" }}
                >
                  {userExpanded ? (
                    <div className="link-button">
                      Hide revoked users{" "}
                      <Icon type="up" style={{ fontSize: "80%" }} />
                    </div>
                  ) : (
                    <div className="link-button">
                      Expand to see revoked users{" "}
                      <Icon type="down" style={{ fontSize: "80%" }} />
                    </div>
                  )}
                </div>

                {userExpanded &&
                  users.filter((user) => user.status !== "Active").length >
                    0 && (
                    <Table
                      scroll={{ x: 800 }}
                      pagination={false}
                      loading={userLoading}
                      dataSource={users.filter(
                        (user) => user.status !== "Active"
                      )}
                      columns={userColumns}
                      onRow={(record, rowIndex) => {
                        return {
                          onDoubleClick: () => {
                            this.props.history.push(
                              `/organisations/${record.organisationId}/users/${record.userId}`
                            );
                          },
                        };
                      }}
                    />
                  )}
              </div>
            )}

            {(authUser.organisationId === organisation.organisationId ||
              keys.length > 0) && (
              <div>
                <div style={{ minHeight: "6vh" }}></div>

                <h2 className="h2">Keys</h2>

                {authUser.organisationId === organisation.organisationId ? (
                  <ButtonKeyAdd organisationId={organisation.organisationId} />
                ) : null}

                <TextLarge>
                  <p>
                    Listed below are all keys for{" "}
                    <strong>{organisation.organisationName}</strong>
                  </p>
                  <p>
                    You can click through to view key information, and if
                    authorised, maintain the status of the key.
                  </p>
                </TextLarge>
                <Table
                  scroll={{ x: 800 }}
                  pagination={paginationActive}
                  onChange={this.handleTableChangeActive}
                  loading={keyLoading}
                  dataSource={keys.filter((key) => key.status === "Active")}
                  columns={keyColumns}
                  onRow={(record, rowIndex) => {
                    return {
                      onDoubleClick: () => {
                        this.props.history.push(
                          `/organisations/${record.organisationId}/keys/${record.kid}`
                        );
                      },
                    };
                  }}
                />
                <div
                  onClick={() => this.handleKeyExpand()}
                  style={{ paddingTop: "48px", paddingBottom: "24px" }}
                >
                  {keyExpanded ? (
                    <div className="link-button">
                      Hide revoked keys{" "}
                      <Icon type="up" style={{ fontSize: "80%" }} />
                    </div>
                  ) : (
                    <div className="link-button">
                      Expand to see revoked keys{" "}
                      <Icon type="down" style={{ fontSize: "80%" }} />
                    </div>
                  )}
                </div>
                {keyExpanded &&
                  keys.filter((key) => key.status !== "Active").length > 0 && (
                    <Table
                      scroll={{ x: 800 }}
                      pagination={paginationRevoked}
                      onChange={this.handleTableChangeRevoked}
                      loading={keyLoading}
                      dataSource={keys.filter((key) => key.status !== "Active")}
                      columns={keyColumns}
                      onRow={(record, rowIndex) => {
                        return {
                          onDoubleClick: () => {
                            this.props.history.push(
                              `/organisations/${record.organisationId}/keys/${record.kid}`
                            );
                          },
                        };
                      }}
                    />
                  )}
              </div>
            )}

            {organisation.types &&
              organisation.types.filter(
                (type) => type === "API Provider"
              )[0] === "API Provider" &&
                (authUser.organisationId === organisation.organisationId ||
                  authServers.length > 0) && (
                <div>
                  <div style={{ minHeight: "6vh" }}></div>
                  <h2 className="h2">Authorisation Servers</h2>
                  {authUser.organisationId === organisation.organisationId ? (
                    <ButtonAuthServerAdd
                      organisationId={organisation.organisationId}
                    />
                  ) : null}
                  <TextLarge>
                    <p>
                      Listed below are all authorisation servers for{" "}
                      <strong>{organisation.organisationName}</strong>
                    </p>
                    <p>
                      You can click through to view authorisation server
                      information, and if authorised, maintain authorisation
                      server information.
                    </p>
                  </TextLarge>
                  <Table
                    scroll={{ x: 800 }}
                    pagination={false}
                    loading={authServerLoading}
                    dataSource={authServers.filter(
                      (authServer) => authServer.status === "Active"
                    )}
                    columns={authServerColumns}
                    onRow={(record, rowIndex) => {
                      return {
                        onDoubleClick: () => {
                          this.props.history.push(
                            `/organisations/${record.organisationId}/auth-servers/${record.authServerId}`
                          );
                        },
                      };
                    }}
                  />
                  <div
                    onClick={() => this.handleAuthServerExpand()}
                    style={{ paddingTop: "48px", paddingBottom: "24px" }}
                  >
                    {authServerExpanded ? (
                      <div className="link-button">
                        Hide revoked auth servers{" "}
                        <Icon type="up" style={{ fontSize: "80%" }} />
                      </div>
                    ) : (
                      <div className="link-button">
                        Expand to see revoked auth servers{" "}
                        <Icon type="down" style={{ fontSize: "80%" }} />
                      </div>
                    )}
                  </div>
                  {authServerExpanded &&
                    authServers.filter(
                      (authServer) => authServer.status !== "Active"
                    ).length > 0 && (
                      <Table
                        scroll={{ x: 800 }}
                        pagination={false}
                        loading={authServerLoading}
                        dataSource={authServers.filter(
                          (authServer) => authServer.status !== "Active"
                        )}
                        columns={authServerColumns}
                        onRow={(record, rowIndex) => {
                          return {
                            onDoubleClick: () => {
                              this.props.history.push(
                                `/organisations/${record.organisationId}/auth-servers/${record.authServerId}`
                              );
                            },
                          };
                        }}
                      />
                    )}
                </div>
              )}
          </CentreLarge>
        )}
      </div>
    );
  }
}

export default withStore(Organisation);
