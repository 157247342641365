import { Col, Row } from "antd";
import React from "react";

const CentreSmall = ({ children, title }) => (
  <div className="content">
    <Row>
      <Col xs={2} sm={4} md={6} lg={7} xl={8} />
      <Col xs={20} sm={16} md={12} lg={10} xl={8}>
        <h1 className="h1" style={{ textAlign: "center" }}>
          {title}
        </h1>
        {children}
      </Col>
      <Col xs={2} sm={4} md={6} lg={7} xl={8} />
    </Row>
  </div>
);

export default CentreSmall;
