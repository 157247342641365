import { Button, Checkbox, Form, message } from "antd";
import React from "react";
import { withRouter } from "react-router-dom";
import CentreLarge from "../components/CentreLarge";
import Terms from "../components/Terms";
import { withStore } from "../components/StoreProvider";
import { makePost } from "../modules/api-client";

class AcceptTermsForm extends React.Component {
  state = {
    data: {},
    submitting: false,
  };

  componentDidMount() {
    const { organisationId, userId } = this.props.match.params;
    const { authUser } = this.props.store;

    if (!(authUser.organisationId === organisationId && authUser.userId === userId)) {
      message.error("User not authorised");
      this.props.history.push("/");
      return;
    } else if (authUser.status === "Active") {
      this.props.history.push("/organisations");
      return;
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { organisationId, userId } = this.props.match.params;
    let { data } = this.state;
    const { authUser } = this.props.store;

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err && values.accept) {
        this.setState({ submitting: true });

        let updatedData = Object.assign({}, data, values);
        this.setState({ data: updatedData });

        let req = updatedData;

        makePost(
            `/organisations/${ organisationId }/users/${ userId }/accept`,
            updatedData,
        )
        .then(() => {
          authUser.status = "Active";
          // @todo: make better use of AppStore.
          sessionStorage.setItem("authUser", JSON.stringify(authUser));
          message.success("Terms accepted");
          this.props.history.push("/organisations");
        })
        .catch((err) => {
          this.setState({ submitting: false });
          if (err.response.status === 401) {
            message.error(`Error: ${err.response.body.data.error}`);
          } else {
            message.error("User is not authorised for this");
          }
        });
      } else if (!values.accept) {
        message.error("Must accept terms and conditions");
      }
    });
  };

  render() {
    const { getFieldDecorator, isFieldTouched } = this.props.form;
    const { submitting } = this.state;

    return (
      <CentreLarge title="Terms and Conditions">
        <Terms />
        <Form onSubmit={this.handleSubmit}>
          <Form.Item>
            {getFieldDecorator(
              "accept",
              {}
            )(
              <Checkbox data-cy="user-accept-terms" value="Accept">
                I accept the terms and conditions
              </Checkbox>
            )}
          </Form.Item>
          <Form.Item>
            <Button
              data-cy="user-accept-terms-btn"
              className="btn btn--edit"
              type="primary"
              htmlType="submit"
              loading={submitting}
              disabled={!isFieldTouched("accept")}
            >
              Accept
            </Button>
          </Form.Item>
        </Form>
      </CentreLarge>
    );
  }
}

let AcceptTerms = Form.create({ name: "acceptTerms" })(AcceptTermsForm);

AcceptTerms = withRouter(AcceptTerms);
AcceptTerms = withStore(AcceptTerms);

export default AcceptTerms;
