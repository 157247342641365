import { Breadcrumb, Button, Form, Input, message, Modal, Select } from "antd";
import moment from "moment";
import React from "react";
import { Link, withRouter } from "react-router-dom";
import CentreBreadcrumb from "../components/CentreBreadcrumb";
import CentreMedium from "../components/CentreMedium";
import TextSmall from "../components/TextSmall";
import { withStore } from "../components/StoreProvider";
import { makeGet, makePut } from "../modules/api-client";

class KeyEditForm extends React.Component {
  state = {
    data: {},
    submitting: false,
    visible: false,
    confirm: true,
    loaded: false,
  };

  componentDidMount() {
    const { organisationId, keyId } = this.props.match.params;

    makeGet(`/organisations/${ organisationId }/keys/${ keyId }`)
      .then((res) => {
        let data = res.body;
        this.setState({ data: data, loaded: true });
      })
      .catch((err) => {
        if (err.response.status === 401) {
          message.error(`Error: ${err.response.body.data.error}`);
        } else if (err.response.status === 403) {
          message.error(`Error: ${err.response.body.data.error}`);
        } else {
          message.error("Try again");
        }
      });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { data, confirmId, confirm } = this.state;
    const { organisationId, keyId } = this.props.match.params;

    if (!(confirm || keyId === confirmId)) {
      message.error("Confirm keyId to update status");
      return;
    }

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ submitting: true });

        var updatedData = Object.assign({}, data, values);
        for (var key in values) {
          if (
            typeof values[key] === "string" ||
            values[key] instanceof String
          ) {
            updatedData[key] = values[key].toString().trim();
          } else {
            updatedData[key] = values[key];
          }
        }
        this.setState({ data: updatedData });

        makePut(
            `/organisations/${ organisationId }/keys/${ keyId }`,
            updatedData,
        )
        .then(() => {
          message.success("Key updated");
          this.props.history.push(`/organisations/${ organisationId }/keys/${ keyId }`);
        })
        .catch((err) => {
          this.setState({ submitting: false });
          message.error(`Error: ${ err.response.body.data.message }`);
        });
      }
    });
  };

  handleOk = () => {
    this.setState({ visible: false });
  };

  handleCancel = () => {
    this.setState({ visible: false, confirmId: "" });
  };

  handleChange = (e) => {
    this.setState({ confirmId: e.target.value });
  };

  showModal = () => {
    this.setState({ visible: true, confirm: false });
  };

  render() {
    const { getFieldDecorator, isFieldTouched } = this.props.form;
    const {
      data,
      confirmId,
      submitting,
      visible,
      loaded,
    } = this.state;
    const { authUser } = this.props.store;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    return (
      <div>
        <CentreBreadcrumb>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={"/"}>
                Home
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={"/organisations"}> All Organisations </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/organisations/${data.organisationId}`}>
                {" "}
                Organisation{" "}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/organisations/${data.organisationId}/keys`}>
                {" "}
                All Keys{" "}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link
                to={`/organisations/${data.organisationId}/keys/${data.kid}`}
              >
                {" "}
                {data.kid}{" "}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <strong> Edit </strong>
            </Breadcrumb.Item>
          </Breadcrumb>
        </CentreBreadcrumb>
        {loaded && (
          <CentreMedium title="Key (Edit)">
            <TextSmall>
              Edit details for Key Id <strong>{data.kid}</strong>
            </TextSmall>
            <Modal
              title="Confirm Update"
              visible={visible}
              onOk={this.handleOk}
              okText="Confirm"
              onCancel={this.handleCancel}
              cancelText="Cancel"
              centered
            >
              <p>
                Type the Key Id to confirm update of the key:{" "}
                <strong>{data.kid}</strong>
              </p>
              <Form.Item>
                <Input onChange={this.handleChange} value={confirmId} />
              </Form.Item>
            </Modal>
            <Form onSubmit={this.handleSubmit}>
              {authUser.organisationId === data.organisationId && (
                <Form.Item {...formItemLayout} label="Key Name">
                  {getFieldDecorator("name", {
                    rules: [
                      { required: true, message: "Key name cannot be blank" },
                    ],
                    initialValue: data.name,
                  })(<Input data-cy="key-name" placeholder="Key name" />)}
                </Form.Item>
              )}
              <Form.Item {...formItemLayout} label="Status">
                {getFieldDecorator("status", {
                  rules: [
                    { required: true, message: "Status cannot be blank" },
                  ],
                  initialValue: data.status,
                })(
                  <Select
                    placeholder="Key status"
                    value={data.status}
                    onChange={this.showModal}
                  >
                    <Select.Option value="Active">Active</Select.Option>
                    <Select.Option value="Revoked">Revoked</Select.Option>
                  </Select>
                )}
              </Form.Item>
              <Form.Item {...tailFormItemLayout}>
                <Button
                  data-cy="key-edit-submit-btn"
                  className="btn btn--edit"
                  type="primary"
                  htmlType="submit"
                  loading={submitting}
                  disabled={
                    !(isFieldTouched("name") || isFieldTouched("status"))
                  }
                >
                  Update
                </Button>
              </Form.Item>
            </Form>
          </CentreMedium>
        )}
      </div>
    );
  }
}

let KeyEdit = Form.create({ name: "keyEdit" })(KeyEditForm);

KeyEdit = withRouter(KeyEdit);
KeyEdit = withStore(KeyEdit);

export default KeyEdit;
