import {Layout, message} from "antd";
import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import AcceptTerms from "./containers/AcceptTerms";
import AuthServer from "./containers/AuthServer";
import AuthServerAdd from "./containers/AuthServerAdd";
import AuthServerEdit from "./containers/AuthServerEdit";
import AuthServers from "./containers/AuthServers";
import Home from "./containers/Home";
import Key from "./containers/Key";
import KeyAdd from "./containers/KeyAdd";
import KeyEdit from "./containers/KeyEdit";
import Keys from "./containers/Keys";
import Login from "./containers/Login";
import Logout from "./containers/Logout";
import LoginB2c from "./containers/LoginB2C";
import LogoutAzureB2C from "./containers/LogoutAzureB2C";
import CompleteAzureB2C from "./containers/CompleteAzureB2C";
import PasswordReset from "./containers/PasswordReset";
import PasswordRequest from "./containers/PasswordRequest";
import Organisation from "./containers/Organisation";
import OrganisationAdd from "./containers/OrganisationAdd";
import OrganisationEdit from "./containers/OrganisationEdit";
import Organisations from "./containers/Organisations";
import ChangePassword from "./containers/ChangePassword";
import User from "./containers/User";
import UserAdd from "./containers/UserAdd";
import UserEdit from "./containers/UserEdit";
import Users from "./containers/Users";
import { withStore } from "./components/StoreProvider";



function userMatchesRole(user, roles) {
    return !roles.length || roles.includes(user.role)
}

function userMatchesOrg(user, orgId) {
    return user.organisationId === orgId
}


class SmartRoute extends React.Component {

    render() {
        const { roles = [], sameOrg = false, ...rest} = this.props;
        const { isHydrated, authUser } = this.props.store;
        const { organisationId } = rest.computedMatch.params;

        if (!isHydrated) {
            // handles the fact that we don't want to reroute because of a missing authUser if we're yet
            // to rehydrate the app store from local storage. Occurs if we reload the page as that kills the store.
            return null;
        }

        const unauthenticated = authUser === null;
        if (unauthenticated) {
            return <Redirect to={ `/login?next=${ this.props.location.pathname }` } />
        }

        const notAcceptedTerms = authUser.status === "Inactive";
        if (notAcceptedTerms) {
            return <Redirect to={ `/organisations/${ authUser.organisationId }/users/${ authUser.userId }/terms` } />
        }

        let permitted = userMatchesRole(authUser, roles);
        if (sameOrg) {
            // even if the route enforces that the users org must match the route org we allow admins through regardless
            // the exception is if the route restricts to non-admin types, in which case being Admin is insufficient.
            // NOTE: The aforementioned preserves the initial functionality pre-rebuild, but seems wrong... admins
            //       should be able to access all the things I think. - RichC
            permitted = permitted && (authUser.role === 'Admin' || userMatchesOrg(authUser, organisationId));
        }

        if (!permitted) {
            message.error("User not authorised");
            return <Redirect to="." />
        }

        return <Route { ...rest } />
    }
}

SmartRoute = withStore(SmartRoute)

let test_login_routes = [];
let azure_b2c_routes = [];

 
// Split old login routes out so we can still run test, otherwise assume azure based login routes
if(__ENVIRONMENT === "test"){
    test_login_routes = [
        <Route
            path="/login"
            component={Login}
        />,        
        <Route
            path="/password/request"
            component={PasswordRequest}
        />,
    ]
} else{

    azure_b2c_routes = [
        <Route
            path="/login"
            component={LoginB2c}
        />,
        <Route
            path="/complete/azuread-b2c-oauth2"
            component={CompleteAzureB2C}
        />,
        
        <Route
            path="/logout/azuread-b2c-oauth2"
            component={LogoutAzureB2C}
        /> ,
        <Route
            path="/logout"
            component={Logout}
        /> ,
        <Route
            path="/password/change"
            component={ChangePassword}
        />        
    ]
}


const App = () => (
    <Layout>
        <Header />
        <Layout.Content className="content">
            <div className="container">
                <Switch>
                { test_login_routes }
                { azure_b2c_routes }

                    <Route
                        path="/organisations/:organisationId/users/:userId/terms"
                        component={AcceptTerms}
                    />
                    <Route
                        path="/organisations/:organisationId/users/:userId/password/reset"
                        component={PasswordReset}
                    />
                    <SmartRoute
                        path="/organisations/:organisationId/keys/add"
                        sameOrg={ true }
                        component={KeyAdd}
                    />
                    <SmartRoute
                        path="/organisations/:organisationId/keys/:keyId/edit"
                        sameOrg={ true }
                        component={ KeyEdit }
                    />
                    <SmartRoute
                        path="/organisations/:organisationId/keys/:keyId"
                        component={Key}
                    />
                    <SmartRoute
                        path="/organisations/:organisationId/keys"
                        component={Keys}
                    />
                    <SmartRoute
                        path="/organisations/:organisationId/auth-servers/add"
                        roles={['API Provider']}
                        sameOrg={ true }
                        component={AuthServerAdd}
                    />
                    <SmartRoute
                        path="/organisations/:organisationId/auth-servers/:authServerId/edit"
                        roles={['API Provider']}
                        sameOrg={ true }
                        component={AuthServerEdit}
                    />
                    <SmartRoute
                        path="/organisations/:organisationId/auth-servers/:authServerId"
                        component={AuthServer}
                    />
                    <SmartRoute
                        path="/organisations/:organisationId/auth-servers"
                        component={AuthServers}
                    />
                    <SmartRoute
                        path="/organisations/:organisationId/users/add"
                        roles={['Admin']}
                        component={UserAdd}
                    />
                    <SmartRoute
                        path="/organisations/:organisationId/users/:userId/edit"
                        sameOrg={ true }
                        component={UserEdit}
                    />
                    <SmartRoute
                        path="/organisations/:organisationId/users/:userId"
                        sameOrg={ true }
                        component={User}
                    />
                    <SmartRoute
                        path="/organisations/:organisationId/users"
                        sameOrg={ true }
                        component={Users}
                    />
                    <SmartRoute
                        path="/organisations/add"
                        roles={['Admin']}
                        component={OrganisationAdd}
                    />
                    <SmartRoute
                        path="/organisations/:organisationId/edit"
                        roles={['Admin']}
                        component={OrganisationEdit}
                    />
                    <SmartRoute
                        path="/organisations/:organisationId"
                        component={Organisation}
                    />
                    <SmartRoute
                        path="/organisations"
                        component={Organisations}
                    />
                    <Route path="/" component={Home}/>
                </Switch>
            </div>
        </Layout.Content>
        <Footer/>
    </Layout>
);

export default App;
