import React from "react";
import { withRouter } from "react-router-dom";
import { withStore } from "../components/StoreProvider";
import { makeGet } from "../modules/api-client";
import CenteredLoader from "../components/CenteredLoader";
import {Alert, Row} from "antd";

import { observer } from 'mobx-react';


class LogoutAzureB2C extends React.Component {
    state = {
        submitting: false,
        data: {},
        next: "",
        error: "",
        error_description: "",
      };

    handleErrors (){
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        if(params.error && params.error_description) {
            this.setState({
                error: params.error,
                error_description:  params.error_description,
            })
        }
    }

    handleLogoutRedirect() {
        const { store } = this.props;
        const { authUser } = store;

        if(store && authUser){
            const { idToken } = store.authUser;
            makeGet(`/users/logout-azureb2c/${idToken}`).then(response => {
                const { logout_url } = response.body;
                console.log("logout redirect", logout_url)
                // user is already authenticated so no need to login again.
                store.clearStoredData().then(() => {
                    console.log("cleared storage", logout_url)
                    window.localStorage.clear(); // clear any additionally local storage as well
                    if (authUser) {
                        store.setAuthUser(null);
                    }
                    console.log("Redirect now", logout_url);
                    window.location.href = logout_url; // redirect to log out url
                })
            });
        }
    }

    render() {
        const {error, error_description} = this.state;

        this.handleErrors();
        this.handleLogoutRedirect();

        return (
            <>
                { error && error_description && (
                    <Row style={{height:"80vh"}} type="flex" justify="space-around" align="middle">
                        <Alert
                            message="Error"
                            description={error_description}
                            type="error"
                            showIcon
                        />
                    </Row>
                )}

                { !error && (
                    <CenteredLoader/>
                )}
            </>
        );
    }

}

let LogoutAzureB2CHandler = observer(LogoutAzureB2C);

LogoutAzureB2CHandler = withRouter(LogoutAzureB2CHandler);
LogoutAzureB2CHandler = withStore(LogoutAzureB2CHandler);

export default LogoutAzureB2CHandler;
