import request from "superagent";
import apiBase from "../utils/apiBase";
import getCookie from "../utils/getCookie";
import {globalMobxStore} from "../index";


function cleanupUnauthenticatedUser(err) {
    // forces the app to rerender in an unauthenticated state if a request is
    // Unauthorized or Forbidden
    if (err.response.status === 401 || err.response.status === 403) {
        globalMobxStore.setAuthUser(null);
    }
}

export function makeGet(endpoint) {
    return new Promise((resolve, reject) => {
        request
        .get(`${apiBase()}/api/${API_DEFAULT_VERSION}${endpoint}`)
        .set("Content-Type", "application/json")
        .set('X-CSRFToken', getCookie('csrftoken'))
        .withCredentials()            
        .then((res) => resolve(res))
        .catch((err) => {
            cleanupUnauthenticatedUser(err);
            reject(err);
        })
    })
}

export function makePut(endpoint, data) {
    return new Promise((resolve, reject) => {
        request
        .put(`${ apiBase() }/api/${ API_DEFAULT_VERSION }${ endpoint }`)
        .set("Content-Type", "application/json")
        .set('X-CSRFToken', getCookie('csrftoken'))
        .withCredentials()            
        .send(data)
        .then((res) => resolve(res))
        .catch((err) => {
            cleanupUnauthenticatedUser(err);
            reject(err);
        })
    })
}

export function makePost(endpoint, data) {
    return new Promise((resolve, reject) => {
        request
        .post(`${ apiBase() }/api/${ API_DEFAULT_VERSION }${ endpoint }`)
        .set("Content-Type", "application/json")
        .set('X-CSRFToken', getCookie('csrftoken'))
        .withCredentials()
        .send(data)
        .then((res) => resolve(res))
        .catch((err) => {
            cleanupUnauthenticatedUser(err);
            reject(err);
        })
    })
}
