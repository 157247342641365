import { Button, Icon } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import CentreButton from "./CentreButton";

const ButtonKeyAdd = ({ organisationId }) => (
  <CentreButton>
    <Link data-cy="add-key-btn"
          to={`/organisations/${organisationId}/keys/add`}>
      <Button className="btn btn--edit">
        Add Key
      </Button>
    </Link>
  </CentreButton>
);

export default ButtonKeyAdd;
