import { Breadcrumb, Divider, Icon, message, Table, Tooltip } from "antd";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import ButtonUserAdd from "../components/ButtonUserAdd";
import CentreBreadcrumb from "../components/CentreBreadcrumb";
import CentreLarge from "../components/CentreLarge";
import TextLarge from "../components/TextLarge";
import { withStore } from "../components/StoreProvider";
import { makeGet } from "../modules/api-client";

class Users extends React.Component {
  state = {
    users: [],
    loading: true,
    expanded: false,
  };

  componentDidMount() {
    const { organisationId } = this.props.match.params;

    message.loading("Users loading...", 0.5);

    makeGet(`/organisations/${ organisationId }/users`)
      .then((res) => {
        let data = res.body.data;
        this.setState({ users: data, loading: false });

        if (data.length === 0) {
          message.warning("No users found");
        } else {
          message.success("Users loaded");
        }
      })
      .catch(() => {
        this.setState({ users: [], loading: false });
      });
  }

  handleExpand = () => {
    let { expanded } = this.state;
    this.setState({ expanded: expanded === false });
  };

  render() {
    const { organisationId } = this.props.match.params;
    const { users, loading, expanded } = this.state;
    const { authUser } = this.props.store;

    const columns = [
      {
        title: () => (
          <Tooltip title="Double click on a row to view details">
            First Name
          </Tooltip>
        ),
        dataIndex: "firstName",
        key: "firstName",
        sorter: (a, b) => a.firstName.localeCompare(b.firstName),
        defaultSortOrder: "ascend",
        width: "15%",
      },
      {
        title: () => (
          <Tooltip title="Double click on a row to view details">
            Last Name
          </Tooltip>
        ),
        dataIndex: "lastName",
        key: "lastName",
        sorter: (a, b) => a.lastName.localeCompare(b.lastName),
        width: "15%",
      },
      { title: "Email", dataIndex: "email", key: "email", width: "25%" },
      { title: "UserId", dataIndex: "userId", key: "userId", width: "25%" },
      {
        title: "Created",
        render: (record) => (
          <span>{moment(record.createdDate).format("YYYY-MM-DD")}</span>
        ),
        sorter: (a, b) =>
          moment(a.createdDate).unix() - moment(b.createdDate).unix(),
        width: "10%",
      },
      { title: "Status", dataIndex: "status", key: "status", width: "10%" },
      {
        title: "Actions",
        dataIndex: "",
        key: "x",
        align: "center",
        width: "15%",
        render: (record) => (
          <span>
            <Link
              to={`/organisations/${record.organisationId}/users/${record.userId}`}
            >
              View
            </Link>
            <Divider type="vertical" />
            {authUser.role === "Admin" || authUser.organisationId === record.organisationId ? (
              <Link
                to={`/organisations/${record.organisationId}/users/${record.userId}/edit`}
              >
                Edit
              </Link>
            ) : (
              <span style={{ color: "rgba(0,0,0,.25)" }}>Edit</span>
            )}
          </span>
        ),
      },
    ];

    return (
      <div>
        <CentreBreadcrumb>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={"/"}>
               Home
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={"/organisations"}> All Organisations </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/organisations/${organisationId}`}>
                {" "}
                Organisation{" "}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <strong> All Users </strong>
            </Breadcrumb.Item>
          </Breadcrumb>
        </CentreBreadcrumb>
        <CentreLarge title="Users">
          {authUser.role === "Admin" ? (
            <ButtonUserAdd organisationId={organisationId} />
          ) : null}
          <TextLarge>
            <p>Listed below are all users for the organisation.</p>
            <p>
              You can click through to view user information and maintain user
              status.
            </p>
          </TextLarge>
          <Table
            scroll={{ x: 800 }}
            pagination={false}
            loading={loading}
            dataSource={users.filter((user) => user.status === "Active")}
            columns={columns}
            onRow={(record, rowIndex) => {
              return {
                onDoubleClick: () => {
                  this.props.history.push(
                    `/organisations/${record.organisationId}/users/${record.userId}`
                  );
                },
              };
            }}
          />
          <div
            onClick={() => this.handleExpand()}
            style={{ paddingTop: "48px", paddingBottom: "24px" }}
          >
            {expanded ? (
              <div className="link-button">
                Hide revoked users{" "}
                <Icon type="up" style={{ fontSize: "80%" }} />
              </div>
            ) : (
              <div className="link-button">
                Expand to see revoked users{" "}
                <Icon type="down" style={{ fontSize: "80%" }} />
              </div>
            )}
          </div>
          {expanded &&
            users.filter((user) => user.status !== "Active").length > 0 && (
              <Table
                scroll={{ x: 800 }}
                pagination={false}
                loading={loading}
                dataSource={users.filter((user) => user.status !== "Active")}
                columns={columns}
                onRow={(record, rowIndex) => {
                  return {
                    onDoubleClick: () => {
                      this.props.history.push(
                        `/organisations/${record.organisationId}/users/${record.userId}`
                      );
                    },
                  };
                }}
              />
            )}
        </CentreLarge>
      </div>
    );
  }
}

export default withStore(Users);
