import React from "react";
import { withRouter } from "react-router-dom";
import { withStore } from "../components/StoreProvider";
import CenteredLoader from "../components/CenteredLoader";
import { observer } from 'mobx-react';
import {Alert, Row} from "antd";


class LogoutPage extends React.Component {
    state = {
        error: "",
        error_description: "",
      };


    redirectToHome() {
        const { store} = this.props;
        const {authUser} = store;

        if (store) {
            window.localStorage.clear(); // clear any additionally local storage as well
            if (authUser) {
                store.setAuthUser(null);
            }
            window.location.href = "/"; // redirect to log out url
        }
    }

    render (){
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        if(params.error && params.error_description) {

            return(
            <Row style={{height:"80vh"}} type="flex" justify="space-around" align="middle">
                <Alert
                    message="Error"
                    description={params.error_description}
                    type="error"
                    showIcon
                />
            </Row>
            )
        }

        this.redirectToHome();

        return(
            <CenteredLoader/>
        )

    }
}

let Logout;

Logout = withRouter(LogoutPage);
Logout = withStore(Logout);

export default Logout;
