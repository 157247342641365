import { Breadcrumb, Button, Form, Icon, Input, message, Select, Row, Col } from "antd";
import React from "react";
import { Link, withRouter } from "react-router-dom";
import CentreBreadcrumb from "../components/CentreBreadcrumb";
import CentreLarge from "../components/CentreLarge";
import TextSmall from "../components/TextSmall";
import { withStore } from "../components/StoreProvider";
import { makePost } from "../modules/api-client";

class KeyAddForm extends React.Component {
  state = {
    data: {},
    fileName: "",
    submitting: false,
    uploaded: false,
  };

  handleChange = (e) => {
    let { data } = this.state;
    // message.loading("Certificate loading...");

    // get the files
    let files = e.target.files;

    // Process each file
    for (var i = 0; i < files.length; i++) {
      let file = files[i];
      this.setState({ fileName: file.name });

      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // On reader load somthing...
      reader.onload = () => {
        data.certificate = reader.result.split("base64,")[1];
        this.setState({ data: data, uploaded: true });
      }; // reader.onload
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let { data } = this.state;
    const { organisationId } = this.props.match.params;

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ submitting: true });

        var updatedData = Object.assign({}, data, values);
        for (var key in updatedData) {
          if (
            typeof updatedData[key] === "string" ||
            updatedData[key] instanceof String
          ) {
            updatedData[key] = updatedData[key].toString().trim();
          }
        }
        this.setState({ data: updatedData });

        makePost(
            `/organisations/${ organisationId }/keys`,
            updatedData,
        )
        .then((res) => {
          message.success("Certificate uploaded");
          message.success("Key added");
          this.props.history.push(`/organisations/${ organisationId }/keys/${ res.body.kid }`);
        })
        .catch((err) => {
          this.setState({ uploaded: false, submitting: false,});

          message.error(`Error: ${ err.response.body.data.message }`);
        });
      }
    });
  };

  render() {
    const { getFieldDecorator, isFieldTouched } = this.props.form;
    const { organisationId } = this.props.match.params;
    const { fileName, uploaded, submitting } = this.state;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 9 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 0,
        },
      },
    };

    return (
      <div>
        <CentreBreadcrumb>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={"/"}>
                Home
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={"/organisations"}> All Organisations </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/organisations/${organisationId}`}>
                {" "}
                Organisation{" "}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/organisations/${organisationId}/keys`}>
                {" "}
                All Keys{" "}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <strong> Add </strong>
            </Breadcrumb.Item>
          </Breadcrumb>
        </CentreBreadcrumb>

        <CentreLarge title="Certificate (Add)">
          <Row type="flex" justify="start" align="middle">
            <Col span={4}>
              <input
                data-cy="key-certificate-file-input"
                type="file"
                name="file"
                id="file"
                className="inputfile"
                onChange={this.handleChange}
                multiple={false}
              />

              <Button data-cy="key-add-cert-btn" className="btn btn--edit" disabled={uploaded && "True"}>
                <label htmlFor="file">
                  Add Certificate
                </label>

              </Button>
            </Col>
            <Col span={6}>
              {fileName.length > 0 && (
                <p>
                  {fileName} <Icon type="check" style={{ fontSize: "80%" }} />
                </p>
              )}
            </Col>
          </Row>
          <TextSmall>
            <p>Add a certificate for your organisation to the API Centre Register.</p>
          </TextSmall>

          <Form onSubmit={this.handleSubmit}>
            <Form.Item {...formItemLayout} label="Environment">
              {getFieldDecorator("environment", {
                rules: [
                  { required: true, message: "Environment cannot be blank" },
                ],
              })(
                <Select
                    data-cy="key-env"
                    placeholder="Key environment"
                    disabled={submitting}
                 >
                  <Select.Option data-cy="key-env-non-production" value="Non-Production">
                    Non-Production
                  </Select.Option>
                  <Select.Option value="Production">
                    Production
                  </Select.Option>
                </Select>
              )}
            </Form.Item>

            <Form.Item {...formItemLayout} label="Key Name">
              {getFieldDecorator("name", {
                rules: [
                  { required: true, message: "Key name cannot be blank" },
                ],
              })(<Input
                  data-cy="key-name"
                  placeholder="Key name"
                  disabled={submitting}
              />)}
            </Form.Item>

            <Form.Item {...formItemLayout} label="Use">
              {getFieldDecorator("use", {
                rules: [{ required: true, message: "Use cannot be blank" }],
              })(
                <Select
                    data-cy="key-use"
                    placeholder="Key use"
                    disabled={submitting}
                >
                  <Select.Option data-cy="key-use-signing" value="sig">
                    Signing
                  </Select.Option>
                </Select>
              )}
            </Form.Item>

            <Form.Item {...tailFormItemLayout}>
              <Button
                data-cy="key-add-submit-btn"
                className="btn btn--edit"
                type="primary"
                htmlType="submit"
                loading={submitting}
                disabled={
                  !(
                    isFieldTouched("environment") ||
                    isFieldTouched("name") ||
                    isFieldTouched("use")
                  )
                }
              >
                Add
              </Button>
            </Form.Item>
          </Form>
        </CentreLarge>
      </div>
    );
  }
}

let KeyAdd = Form.create({ name: "keyAdd" })(KeyAddForm);

KeyAdd = withRouter(KeyAdd);
KeyAdd = withStore(KeyAdd);

export default KeyAdd;
