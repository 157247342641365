import { Col, Layout, message, Row } from "antd";
import React from "react";
import { Link, NavLink } from "react-router-dom";
import { withStore } from "./StoreProvider";

class Footer extends React.Component {
    state = {
        width: 0,
    };

    componentDidMount() {
        this.setState(
          { width: window.innerWidth },
          window.addEventListener("resize", ({ target }) =>
            this.setState({ width: target.innerWidth })
          )
        );
    }

  handleLogout = () => {
    message.success("User logged out");
    sessionStorage.removeItem("authUser");
    this.props.history.push("/");
  };

    render() {
        let {width, visible} = this.state;
        const { authUser } = this.props.store;

        return (
            <Layout.Footer className="footer" id="footer">
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    {width <= 650 && (authUser?.status === "Active" || authUser?.status === "Inactive") && authUser?.role === "Admin" && (
                        <>
                            <ul className="list-unstyled footer__menu-list">
                                <li>
                                    <h2>Organisations</h2>
                                </li>
                                <li>
                                    <Link to={"/organisations"}>
                                        All organisations
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/organisations/add"}>
                                        Add organisation
                                    </Link>
                                </li>
                            </ul>
                            <ul className="list-unstyled footer__menu-list">
                                <li>
                                    <h2>Profiles</h2>
                                </li>
                                <li>
                                    <Link to={`/organisations/${authUser?.organisationId}`}>
                                        My organisation
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`/organisations/${authUser?.organisationId}/users/${authUser?.userId}`}>
                                        My profile
                                    </Link>
                                </li>
                            </ul>
                        </>
                    )}
                    {width <= 650 && (authUser?.status === "Active" || authUser?.status === "Inactive") && authUser?.role === "API Provider" && (
                        <>
                            <ul className="list-unstyled footer__menu-list">
                                <li>
                                    <h2>Organisations</h2>
                                </li>
                                <li>
                                    <Link to={"/organisations"}>
                                        View organisations
                                    </Link>
                                </li>
                                <li>
                                    <Link  to={`/organisations/${authUser?.organisationId}/keys/add`}>
                                        Add key
                                    </Link>
                                </li>
                                 <li>
                                    <Link to={`/organisations/${authUser?.organisationId}/auth-servers/add`}>
                                        Add Auth Server
                                    </Link>
                                </li>
                            </ul>
                            <ul className="list-unstyled footer__menu-list">
                                <li>
                                    <h2>Profiles</h2>
                                </li>
                                <li>
                                    <Link to={`/organisations/${authUser?.organisationId}`}>
                                        My organisation
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`/organisations/${authUser?.organisationId}/users/${authUser?.userId}`}>
                                        My profile
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`/organisations/${authUser?.organisationId}/keys`}>
                                        My keys
                                    </Link>
                                </li>
                                 <li>
                                    <Link to={`/organisations/${authUser?.organisationId}/auth-servers`}>
                                        My Auth Servers
                                    </Link>
                                </li>
                            </ul>
                        </>
                    )}
                    {width <= 650 && (
                        <ul className="list-unstyled footer__menu-list">
                            <li>
                                <h2>Support</h2>
                            </li>
                            <li>
                                <Link
                                to={{ pathname: "https://status.trustcentre.co.nz/" }}
                                target="_blank"
                                >
                                    Current status
                                </Link>
                            </li>
                            <li>
                                <Link
                                to={{ pathname: "https://paymentsnz.atlassian.net/servicedesk/customer/portal/1/group/7/create/10024" }}
                                target="_blank"
                                >
                                    Report an issue
                                </Link>
                            </li>
                        </ul>
                    )}
                    {width <= 650 && (authUser?.status === "Active" || authUser?.status === "Inactive") && (
                        <NavLink className="btn btn--footer" to="/">
                           <div onClick={this.handleLogout}>
                              Log out
                           </div>
                        </NavLink>
                    )}
                        <p className="footer__text">
                            Contact us by email {width <= 650 && (<br/>)}
                            <a href="mailto:apicentre@paymentsnz.co.nz">apicentre@paymentsnz.co.nz</a> {width <= 650 && (<br/>)}
                            or call on <a href="tel:093001722">(09) 300 1722</a>
                        </p>
                    </Col>
                </Row>
            </Layout.Footer>
        );
    }
}

export default withStore(Footer);
