import { Button, Icon } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import CentreButton from "./CentreButton";

const ButtonUserAdd = ({ organisationId }) => (
  <CentreButton>
    <Link to={`/organisations/${organisationId}/users/add`}
          data-cy="add-user-btn">
      <Button className="btn btn--edit">
        Add User
      </Button>
    </Link>
  </CentreButton>
);

export default ButtonUserAdd;
