import { Col, Row } from "antd";
import React from "react";

const TextLarge = ({ children }) => (
  <div>
    <Row gutter={2}>
      <Col xs={24} sm={24} md={0} lg={0} xl={0}>
        {children}
      </Col>
      <Col xs={0} sm={0} md={18} lg={12} xl={12}>
        {children}
      </Col>
      <Col xs={0} sm={0} md={6} lg={12} xl={12} />
    </Row>
  </div>
);

export default TextLarge;
