export default function apiBase() {
    // noinspection HttpUrlsUsage,JSUnresolvedVariable
    if (__ENVIRONMENT === 'development') { // webpack global var
        return `https://${ window.location.host }`
    }

    if (__ENVIRONMENT === 'test') { // django port in test/ui
        return `http://0.0.0.0:8000`
    }    

    return `https://api.${ window.location.host }`
}
