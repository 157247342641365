import { Breadcrumb, Button, Checkbox, Form, Input, message, Select } from "antd";
import React from "react";
import { Link, withRouter } from "react-router-dom";
import CentreBreadcrumb from "../components/CentreBreadcrumb";
import CentreLarge from "../components/CentreLarge";
import TextSmall from "../components/TextSmall";
import { withStore } from "../components/StoreProvider";
import { makeGet, makePut} from "../modules/api-client";
import CenteredLoader from "../components/CenteredLoader";

class OrganisationEditForm extends React.Component {
  state = {
    data: {},
    submitting: false,
    loaded: false,
  };

  componentDidMount() {
    const { organisationId } = this.props.match.params;

    makeGet(`/organisations/${ organisationId }`)
      .then((res) => {
        let data = res.body.data;
        this.setState({ data: data, loaded: true });
      })
      .catch((err) => {
        if (err.response.status === 401) {
          message.error(`Error: ${err.response.body.data.error}`);
        } else if (err.response.status === 403) {
          message.error(`Error: ${err.response.body.data.error}`);
        } else {
          message.error("Try again");
        }
      });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { organisationId } = this.props.match.params;
    let { data } = this.state;

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ submitting: true });

        var updatedData = Object.assign({}, data, values);
        for (var key in values) {
          if (
            typeof values[key] === "string" ||
            values[key] instanceof String
          ) {
            updatedData[key] = values[key].toString().trim();
          } else {
            updatedData[key] = values[key];
          }
        }
        this.setState({ data: updatedData });

        makePut(
            `/organisations/${ organisationId }`,
            { data: updatedData },
        )
        .then(() => {
          message.success("Organisation updated");
          this.props.history.push(`/organisations/${ organisationId }`);
        })
        .catch((err) => {
          this.setState({ submitting: false });
          message.error(`Error: ${ err.response.body.data.error }`);
        });
      }
    });
  };

  render() {
    const { getFieldDecorator, isFieldTouched } = this.props.form;
    const { data, submitting, loaded } = this.state;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 9 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 0,
        },
      },
    };

    return (
      <div>
        <CentreBreadcrumb>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={"/"}>
                Home
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={"/organisations"}> All Organisations </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/organisations/${data.organisationId}`}>
                {" "}
                {data.organisationName}{" "}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <strong> Edit </strong>
            </Breadcrumb.Item>
          </Breadcrumb>
        </CentreBreadcrumb>

        {!loaded && (
            <CenteredLoader/>
        )}

        {loaded && (
          <CentreLarge title="Organisation (Edit)">
            <TextSmall>
              Edit details for <strong>{data.organisationName}</strong>
            </TextSmall>

            <Form onSubmit={this.handleSubmit} style={{ textAlign: "left" }}>
              <Form.Item {...formItemLayout} label="Organisation Name">
                {getFieldDecorator("organisationName", {
                  rules: [
                    {
                      required: true,
                      message: "Organisation name cannot be blank",
                    },
                  ],
                  initialValue: data.organisationName,
                })(<Input
                    data-cy="org-name"
                    placeholder="Organisation name"
                    disabled="true"

                />)}
              </Form.Item>

              <Form.Item {...formItemLayout} label="NZBN">
                {getFieldDecorator("NZBN", {
                  rules: [{ required: true, message: "NZBN cannot be blank" }],
                  initialValue: data.NZBN,
                })(<Input
                    data-cy="org-nzbn"
                    placeholder="Organisation NZBN"
                    disabled={submitting}
                />)}
              </Form.Item>

              <Form.Item {...formItemLayout} label="Types">
                {getFieldDecorator("types", {
                  rules: [
                    { required: true, message: "Types cannot be blank" },
                  ],
                  initialValue: data.types,
                })(
                  <Checkbox.Group
                      placeholder="Organisation types"
                      disabled="true"
                  >
                    <Checkbox value="API Provider">API Provider</Checkbox>
                    <Checkbox value="Third Party">Third Party</Checkbox>
                  </Checkbox.Group>
                )}
              </Form.Item>

              <Form.Item {...formItemLayout} label="Status">
                {getFieldDecorator("status", {
                  rules: [
                    { required: true, message: "Status cannot be blank" },
                  ],
                  initialValue: data.status,
                })(
                  <Select
                      placeholder="Organisation status"
                      disabled={submitting}
                  >
                    <Select.Option value="Active">Active</Select.Option>
                    <Select.Option value="Revoked">Revoked</Select.Option>
                  </Select>
                )}
              </Form.Item>

              <Form.Item {...tailFormItemLayout}>
                <Button
                  data-cy="org-edit-submit-btn"
                  className="btn btn--edit"
                  type="primary"
                  htmlType="submit"
                  loading={submitting}
                  disabled={
                    !(
                      isFieldTouched("organisationName") ||
                      isFieldTouched("NZBN") ||
                      isFieldTouched("types") ||
                      isFieldTouched("status")
                    )
                  }
                >
                  Update
                </Button>
              </Form.Item>
            </Form>
          </CentreLarge>
        )}
      </div>
    );
  }
}

let OrganisationEdit = Form.create({ name: "organisationEdit" })(OrganisationEditForm);

OrganisationEdit = withRouter(OrganisationEdit);
OrganisationEdit = withStore(OrganisationEdit);

export default OrganisationEdit;
