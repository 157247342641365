import { Icon, Input, message, Popover } from "antd";
import React from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import { withStore } from "./StoreProvider";
import { makeGet } from "../modules/api-client";

class Header extends React.Component {
  state = {
    visible: false,
    width: 0,
  };

  componentDidMount() {
    this.setState(
      { width: window.innerWidth },
      window.addEventListener("resize", ({ target }) =>
        this.setState({ width: target.innerWidth })
      )
    );
  }

  handleVisibleChange = (visible) => {
    this.setState({ visible });
  };

  handleSearch = (value) => {
    if (value !== "") {
      this.props.history.push(
        `/organisations?search=${encodeURIComponent(value)}`
      );
    } else {
      this.props.history.push("/organisations");
    }

    this.setState({ visible: false });
  };

  handleLogout = () => {
    const { store } = this.props;
    window.location.href = "/logout/azuread-b2c-oauth2"
  };

  render() {
    let { width, visible } = this.state;
    const { authUser } = this.props.store;

    return (
      <div className="top-menu">
        <NavLink
          className="top-menu__logo-link"
          to="/"
        >
          <img
          alt=""
          src="/static/images/apicentre-logo.svg"
          />
          <span className="site-domain">Register</span>
        </NavLink>
        <div className="top-menu__right">
          {width > 650 && authUser?.status === "Active" ? (
          <>

            <Popover
              className="top-menu__item"
              content={
                <Input.Search
                  placeholder="Search for organisations"
                  mode="combobox"
                  optionLabelProp="label"
                  style={{ width: "250px" }}
                  showArrow={false}
                  filterOption={false}
                  onSearch={this.handleSearch}
                />
              }
              trigger="click"
              placement="bottomRight"
              visible={visible}
              onVisibleChange={this.handleVisibleChange}
            >
              <Icon type="search" />
            </Popover>

            <Popover
              className="top-menu__item"
              content={
                authUser?.role === "Admin" ? (
                  <ul className="list-unstyled">
                    <li className="pop-li">
                      <Link to={"/organisations"}>
                        View Organisations
                      </Link>
                    </li>
                    <li className="pop-li">
                      <Link to={"/organisations/add"}>
                        Add Organisation
                      </Link>
                    </li>
                  </ul>
                ) : authUser?.role === "API Provider" ? (
                  <ul className="list-unstyled">
                    <li className="pop-li">
                      <Link to={"/organisations"}>
                        View Organisations
                      </Link>
                    </li>
                    <li className="pop-li">
                      <Link
                        to={`/organisations/${authUser?.organisationId}/keys/add`}
                      >
                        Add Key
                      </Link>
                    </li>
                    <li className="pop-li">
                      <Link
                        to={`/organisations/${authUser?.organisationId}/auth-servers/add`}
                      >
                        Add Auth Server
                      </Link>
                    </li>
                  </ul>
                ) : (
                  <ul className="list-unstyled">
                    <li className="pop-li">
                      <Link to={"/organisations"}>
                        View Organisations
                      </Link>
                    </li>
                    <li className="pop-li">
                      <Link
                        to={`/organisations/${authUser?.organisationId}/keys/add`}
                      >
                        Add Key
                      </Link>
                    </li>
                  </ul>
                )
              }
              trigger="click"
              placement="bottomRight"
            >
              Organisations
            </Popover>

            <Popover
              className="top-menu__item"
              content={
                <ul className="list-unstyled">
                  <li className="pop-li">
                    <Link
                     to={{ pathname: "https://status.trustcentre.co.nz/" }}
                     target="_blank"
                    >
                      Current status
                    </Link>
                  </li>
                  <li className="pop-li">
                    <Link
                      to={{ pathname: "https://paymentsnz.atlassian.net/servicedesk/customer/portal/1/group/7/create/10024" }}
                      target="_blank"
                    >
                      Report an issue
                    </Link>
                  </li>
                </ul>
              }
              trigger="click"
              placement="bottomRight"
            >
              Support
            </Popover>

            <Popover
              data-cy="header-profile"
              className="top-menu__item header-profile btn active"
              content={
                <ul className="list-unstyled">

                  <li className="pop-li">
                    <Link to={`/organisations/${authUser?.organisationId}/users/${authUser?.userId}`}>
                      My profile
                    </Link>
                  </li>

                  <li className="pop-li">
                    <Link
                        data-cy="header-profile-my-organisation"
                        to={`/organisations/${authUser?.organisationId}`}>
                      My Organisation
                    </Link>
                  </li>

                  {authUser?.role !== "Admin" && (
                    <li className="pop-li">
                      <Link
                        to={`/organisations/${authUser?.organisationId}/keys`}
                      >
                        My Keys
                      </Link>
                    </li>
                  )}

                  {authUser?.role === "API Provider" && (
                    <li className="pop-li">
                      <Link
                        to={`/organisations/${authUser?.organisationId}/auth-servers`}
                      >
                        My Auth Servers
                      </Link>
                    </li>
                  )}

                  {authUser && (
                    <li className="pop-li">
                      <a onClick={this.handleLogout}>
                        Log out
                      </a>
                    </li>
                  )}


                </ul>
              }
              trigger="click"
              placement="bottomRight"
            >
              <div data-cy="header-logout-btn">
                <div style={
                    {
                      "font-size" : "small",
                      "padding-left": "10px",
                      "padding-right": "10px"
                    }
                  }>
                  {authUser?.firstName} {authUser?.lastName}
                </div>
              </div>
          </Popover>
          </>
          ) : (
          <>
          {width > 650 ? (
          <>
            <Popover
              className="top-menu__item"
              content={
              <ul className="list-unstyled">
                <li className="pop-li">
                  <Link
                   to={{ pathname: "https://status.trustcentre.co.nz/" }}
                   target="_blank"
                  >
                    Current status
                  </Link>
                </li>
                <li className="pop-li">
                  <Link
                    to={{ pathname: "https://paymentsnz.atlassian.net/servicedesk/customer/portal/1/group/7/create/10024" }}
                    target="_blank"
                  >
                    Report an issue
                  </Link>
                </li>
              </ul>
              }
              trigger="click"
              placement="bottomRight"
            >
              Support
            </Popover>
            <NavLink className="btn" to="/login">
              <span>
                Log in
              </span>
            </NavLink>
          </>
          ) : (
          <>
          { authUser?.status === "Active" && (
            <Popover
              className="top-menu__item"
              content={
                <Input.Search
                  placeholder="Search for organisations"
                  mode="combobox"
                  optionLabelProp="label"
                  style={{ width: "250px" }}
                  showArrow={false}
                  filterOption={false}
                  onSearch={this.handleSearch}
                />
              }
              trigger="click"
              placement="bottomRight"
              visible={visible}
              onVisibleChange={this.handleVisibleChange}
            >
              <Icon type="search" />
            </Popover>
          )}
            <a href="#footer">
              <Icon type="menu" />
            </a>
          </>
          )}
        </>
        )}
        </div>
      </div>
    );
  }
}

Header = withStore(Header);
Header = withRouter(Header);

export default Header;
