import { Breadcrumb, Button, Form, Input, message, Select } from "antd";
import React from "react";
import { Link, withRouter } from "react-router-dom";
import CentreBreadcrumb from "../components/CentreBreadcrumb";
import CentreMedium from "../components/CentreMedium";
import { isValidURI } from "../components/Common";
import TextSmall from "../components/TextSmall";
import { withStore } from "../components/StoreProvider";
import { makeGet, makePut } from "../modules/api-client";
import CenteredLoader from "../components/CenteredLoader";

class AuthServerEditForm extends React.Component {
  state = {
    data: {},
    submitting: false,
    loaded: false,
  };

  componentDidMount() {
    const { organisationId, authServerId } = this.props.match.params;

    makeGet(`/organisations/${ organisationId }/auth-servers/${ authServerId }`)
      .then((res) => {
        let data = res.body.data;
        this.setState({ data: data, loaded: true });
      })
      .catch((err) => {
        if (err.response.status === 401) {
          message.error(`Error: ${err.response.body.data.error}`);
        } else if (err.response.status === 403) {
          message.error(`Error: ${err.response.body.data.error}`);
        } else {
          message.error("Try again");
        }
      });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { data } = this.state;
    const { organisationId, authServerId } = this.props.match.params;

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ submitting: true });

        var updatedData = Object.assign({}, data, values);
        for (var key in values) {
          if (
            typeof values[key] === "string" ||
            values[key] instanceof String
          ) {
            updatedData[key] = values[key].toString().trim();
          } else {
            updatedData[key] = values[key];
          }
        }
        this.setState({ data: updatedData });

        makePut(
            `/organisations/${ organisationId }/auth-servers/${ authServerId }`,
            { data: updatedData },
        )
        .then(() => {
          message.success("Auth server updated");
          this.props.history.push(`/organisations/${ organisationId }/auth-servers/${ authServerId }`);
        })
        .catch((err) => {
          this.setState({ submitting: false });
          message.error(`Error: ${ err.response.body.data.error }`);
        });
      }
    });
  };

  validateURI = (rule, value, callback) => {
    if (value === null || value.length === 0) {
      callback();
      return;
    } else if (isValidURI(value)) {
      callback();
      return;
    }
    callback("Must be a valid URI");
  };

  render() {
    const { getFieldDecorator, isFieldTouched } = this.props.form;
    const { data, submitting, loaded } = this.state;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    return (
      <div>
        <CentreBreadcrumb>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={"/"}>
                Home
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={"/organisations"}> All Organisations </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/organisations/${data.organisationId}`}>
                {" "}
                Organisation{" "}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/organisations/${data.organisationId}/auth-servers`}>
                {" "}
                All Authorisation Servers{" "}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link
                to={`/organisations/${data.organisationId}/auth-servers/${data.authServerId}`}
              >
                {" "}
                {data.authServerId}{" "}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <strong> Edit </strong>
            </Breadcrumb.Item>
          </Breadcrumb>
        </CentreBreadcrumb>

        {!loaded && (
            <CenteredLoader/>
        )}

        {loaded && (
          <CentreMedium title="Authorisation Server (Edit)">
            <TextSmall>
              Edit details of the authorisation server{" "}
              <strong>{data.authServerId}</strong>
            </TextSmall>
            <Form onSubmit={this.handleSubmit}>
              <Form.Item {...formItemLayout} label="Environment">
                {getFieldDecorator("environment", {
                  rules: [
                    { required: true, message: "Environment cannot be blank" },
                  ],
                  initialValue: data.environment,
                })(
                  <Select
                    placeholder="Auth server environment"
                    disabled={submitting}
                  >
                    <Select.Option value="Non-Production">
                      Non-Production
                    </Select.Option>
                    <Select.Option value="Production">Production</Select.Option>
                  </Select>
                )}
              </Form.Item>
              <Form.Item {...formItemLayout} label="Name">
                {getFieldDecorator("name", {
                  rules: [{ required: true, message: "Name cannot be blank" }],
                  initialValue: data.name,
                })(<Input
                    data-cy="auth-server-name"
                    disabled={submitting}
                    placeholder="Auth server name"
                />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="Description">
                {getFieldDecorator("description", {
                  rules: [{ required: false }],
                  initialValue: data.description || "",
                })(<Input
                    placeholder="Auth server description"
                    disabled={submitting}
                />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="Base API URI">
                {getFieldDecorator("baseAPIURI", {
                  rules: [{ required: false }, { validator: this.validateURI }],
                  initialValue: data.baseAPIURI || "",
                })(<Input
                    placeholder="Auth server base API URI"
                    disabled={submitting}
                />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="Well Known URI">
                {getFieldDecorator("wellKnownURI", {
                  rules: [{ required: false }, { validator: this.validateURI }],
                  initialValue: data.wellKnownURI || "",
                })(<Input
                    placeholder="Auth server well known URI"
                    disabled={submitting}
                />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="Developer Portal URI">
                {getFieldDecorator("portalURI", {
                  rules: [{ required: false }, { validator: this.validateURI }],
                  initialValue: data.portalURI || "",
                })(<Input
                    placeholder="Auth server portal URI"
                    disabled={submitting}
                />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="Logo URI">
                {getFieldDecorator("logoURI", {
                  rules: [{ required: false }, { validator: this.validateURI }],
                  initialValue: data.logoURI || "",
                })(<Input
                  placeholder="Auth server logo URI"
                  disabled={submitting}
                />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="Status">
                {getFieldDecorator("status", {
                  rules: [
                    { required: true, message: "Status cannot be blank" },
                  ],
                  initialValue: data.status,
                })(
                  <Select
                    placeholder="Auth server status"
                    disabled={submitting}
                  >
                    <Select.Option value="Active">Active</Select.Option>
                    <Select.Option value="Revoked">Revoked</Select.Option>
                  </Select>
                )}
              </Form.Item>
              <Form.Item {...tailFormItemLayout}>
                <Button
                  data-cy="auth-server-edit-submit-btn"
                  className="btn btn--edit"
                  type="primary"
                  htmlType="submit"
                  loading={submitting}
                  disabled={
                    !(
                      isFieldTouched("environment") ||
                      isFieldTouched("name") ||
                      isFieldTouched("description") ||
                      isFieldTouched("baseAPIURI") ||
                      isFieldTouched("logoURI") ||
                      isFieldTouched("portalURI") ||
                      isFieldTouched("wellKnownURI") ||
                      isFieldTouched("status")
                    )
                  }
                >
                  Update
                </Button>
              </Form.Item>
            </Form>
          </CentreMedium>
        )}
      </div>
    );
  }
}

let AuthServerEdit = Form.create({ name: "authServerEdit" })(AuthServerEditForm);

AuthServerEdit = withRouter(AuthServerEdit);
AuthServerEdit = withStore(AuthServerEdit);

export default AuthServerEdit;
