import { Button, Form, Input, message } from "antd";
import React from "react";
import { withRouter } from "react-router-dom";
import CentreSmall from "../components/CentreSmall";
import { makePost } from "../modules/api-client";

class PasswordResetForm extends React.Component {
  state = {
    data: {},
    token: "",
    submitting: false,
  };
  helpText = (
    <div>
      Your password must:
      <ul>
        <li>be at least 8 characters long</li>
        <li>have a mix of upper case and lower case alpha characters</li>
        <li>include a number</li>
        <li>not be too similar to your other personal information</li>
        <li>not be a commonly used password</li>
      </ul>
    </div>
  )

  componentDidMount() {
    const { search } = this.props.location;

    let token = ''
    if (search) {
      token = search.split('?token=')[1];
    }

    if (!token) {
      message.error("User not authorised", 5);
      this.props.history.push("/");
      return;
    }

    this.setState({ token: token });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { organisationId, userId } = this.props.match.params;
    let { data, token } = this.state;

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ submitting: true });

        let updatedData = Object.assign({}, data, values);
        this.setState({ data: updatedData });

        makePost(
            `/organisations/${ organisationId }/users/${ userId }/password?token=${ token }`,
            { data: updatedData },
        )
        .then(() => {
          message.success("Password updated", 10);
          this.props.history.push("/login");
        })
        .catch((err) => {
          this.setState({ submitting: false });
          if (err.response.status === 401) {
            message.error("User is not authorised for this", 5);
          } else {
            message.error(`Error: ${ err.response.body.data.error }`, 10);
          }
        });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { submitting } = this.state;

    return (
      <CentreSmall title="Set Password">
        <Form onSubmit={this.handleSubmit}>
          <Form.Item>
            {getFieldDecorator("password", {
              rules: [
                { required: true, message: "Password cannot be blank" },
              ],
            })(
              <Input data-cy="pw-reset-one" size="default" type="password" placeholder="Set password" />
            )}
          </Form.Item>
          { this.helpText }
          <Form.Item>
            {getFieldDecorator("confirm", {
              rules: [
                { required: true, message: "Confirm your password" },
              ],
            })(
              <Input data-cy="pw-reset-two" size="default" type="password" placeholder="Confirm password" onBlur={ this.handleConfirmBlur } />
            )}
          </Form.Item>
          <Form.Item>
            <Button data-cy="pw-reset-submit-btn"  className="btn" type="primary" htmlType="submit" loading={submitting}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </CentreSmall>
    );
  }
}

const PasswordReset = Form.create({ name: "passwordReset" })(PasswordResetForm);

export default withRouter(PasswordReset);
