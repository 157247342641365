import React from "react";
import { Button, Form, Input, message } from "antd";
import { Link, withRouter } from "react-router-dom";
import CentreSmall from "../components/CentreSmall";
import { withStore } from "../components/StoreProvider";
import {makePost} from "../modules/api-client";


class LoginForm extends React.Component {
  state = {
    submitting: false,
    data: {},
    next: "",
  };

  componentDidMount() {
    const { search } = this.props.location;
    const { authUser } = this.props.store;

    let next = "/organisations";

    if (search) {
      next = search.split("?next=")[1];
    }
    this.setState({ next: next });

    // user is already authenticated so no need to login again.
    if (authUser) {
      this.props.history.push(next);
      return;
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    let { data, next } = this.state;
    const { store } = this.props;

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ submitting: true });

        let updatedData = Object.assign({}, data, values);
        this.setState({ data: updatedData });

        makePost(
            '/users/login',
            { data: updatedData },
        )
        .then((res) => {
          message.success("User logged in");
          store.setAuthUser(res.body.data.user);
          this.props.history.push(next);
        })
        .catch((err) => {
          this.setState({ submitting: false });
          if (err.response.status === 401) {
            message.error(`Error: ${err.response.body.data.message}`);
          } else {
            message.error("Try again");
          }
        });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { submitting } = this.state;

    return (
      <CentreSmall title="Login">
        <Form onSubmit={this.handleSubmit}>
          <Form.Item>
            {getFieldDecorator("email", {
              rules: [{ required: true, message: "Email cannot be blank" }],
            })(
              <Input
                size="default"
                placeholder="Email"
                data-cy="login-email"
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("password", {
              rules: [{ required: true, message: "Password cannot be blank" }],
            })(
              <Input
                size="default"
                type="password"
                placeholder="Password"
                data-cy="login-password"
              />
            )}
          </Form.Item>
          <Form.Item>
            <Button className="btn"
                    type="primary"
                    htmlType="submit"
                    loading={submitting}
                    data-cy="login-submit-btn"
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
        <Link className="color-blue"
              to="/password/request"
              data-cy="login-forgot-pw-link"
        >
          <p>Forgot password?</p>
        </Link>
      </CentreSmall>
    );
  }
}


let Login;

Login = Form.create({ name: "login" })(LoginForm); // Use old login form for tests
Login = withRouter(Login);
Login = withStore(Login);

export default Login;
