import { createBrowserHistory } from "history";
import React from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { StoreProvider } from "./components/StoreProvider";
import AppStore from "./models/app";

export default createBrowserHistory();
const history = createBrowserHistory();

export const globalMobxStore = new AppStore();


ReactDOM.render(
    <StoreProvider store={ globalMobxStore }>
        <Router history={ history }>
            <App />
        </Router>
    </StoreProvider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

if (module.hot) {
  module.hot.accept();
}
