import {Row} from "antd";
import React from "react";

const CenteredLoader = () => (
    <Row style={{height:"80vh"}} type="flex" justify="space-around" align="middle">
      <div className="ant-spin ant-spin-spinning">
        <span className="ant-spin-dot ant-spin-dot-spin">
          <i className="ant-spin-dot-item"></i>
          <i className="ant-spin-dot-item"></i>
          <i className="ant-spin-dot-item"></i>
          <i className="ant-spin-dot-item"></i>
        </span>
      </div>
    </Row>
);

export default CenteredLoader;


