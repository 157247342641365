import { Breadcrumb, Divider, Icon, message, Table, Tooltip } from "antd";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import ButtonAuthServerAdd from "../components/ButtonAuthServerAdd";
import CentreBreadcrumb from "../components/CentreBreadcrumb";
import CentreLarge from "../components/CentreLarge";
import TextLarge from "../components/TextLarge";
import { withStore } from "../components/StoreProvider";
import { makeGet } from "../modules/api-client";

class AuthServers extends React.Component {
  state = {
    authServers: [],
    loading: true,
    expanded: false,
  };

  componentDidMount() {
    const { organisationId } = this.props.match.params;

    message.loading("Auth servers loading...", 0.5);

    makeGet(`/organisations/${ organisationId }/auth-servers`)
      .then((res) => {
        let data = res.body.data;
        this.setState({ authServers: data, loading: false });
        if (data.length === 0) {
          message.warning("No auth servers found");
        } else {
          message.success("Auth servers loaded");
        }
      })
      .catch(() => {
        this.setState({ authServers: [], loading: false });
      });
  }

  handleExpand = () => {
    let { expanded } = this.state;
    this.setState({ expanded: expanded === false });
  };

  render() {
    const { organisationId } = this.props.match.params;
    const { authServers, loading, expanded } = this.state;
    const { authUser } = this.props.store;

    const columns = [
      {
        title: () => (
          <Tooltip title="Double click on a row to view details">
            Environment
          </Tooltip>
        ),
        dataIndex: "environment",
        key: "environment",
        sorter: (a, b) => a.environment.localeCompare(b.environment),
        width: "25%",
      },
      { title: "Name", dataIndex: "name", key: "name", width: "30%" },
      {
        title: "Created",
        render: (record) => (
          <span>{moment(record.createdDate).format("YYYY-MM-DD")}</span>
        ),
        sorter: (a, b) =>
          moment(a.createdDate).unix() - moment(b.createdDate).unix(),
        defaultSortOrder: "descend",
        width: "20%",
      },
      { title: "Status", dataIndex: "status", key: "status", width: "10%" },
      {
        title: "Actions",
        dataIndex: "",
        key: "x",
        align: "center",
        width: "15%",
        render: (record) => (
          <span>
            <Link
              to={`/organisations/${record.organisationId}/auth-servers/${record.authServerId}`}
            >
              View
            </Link>
            <Divider type="vertical" />
            {record.organisationId === authUser.organisationId ? (
              <Link
                to={`/organisations/${record.organisationId}/auth-servers/${record.authServerId}/edit`}
              >
                Edit
              </Link>
            ) : (
              <span style={{ color: "rgba(0,0,0,.25)" }}>Edit</span>
            )}
          </span>
        ),
      },
    ];

    return (
      <div>
        <CentreBreadcrumb>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={"/"}>
               Home
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={"/organisations"}> All Organisations </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/organisations/${organisationId}`}>
                {" "}
                Organisation{" "}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <strong> All Authorisation Servers </strong>
            </Breadcrumb.Item>
          </Breadcrumb>
        </CentreBreadcrumb>
        <CentreLarge title="Authorisation Servers">
          {organisationId === authUser.organisationId ? (
            <ButtonAuthServerAdd organisationId={organisationId} />
          ) : null}
          <TextLarge>
            <p>
              Listed below are all authorisation servers for the organisation.
            </p>
            <p>
              You can click through to view authorisation server information,
              and if authorised, maintain authorisation server information.
            </p>
          </TextLarge>
          {authServers && (
            <div>
              <Table
                scroll={{ x: 800 }}
                pagination={false}
                loading={loading}
                dataSource={authServers.filter(
                  (authServer) => authServer.status === "Active"
                )}
                columns={columns}
                onRow={(record, rowIndex) => {
                  return {
                    onDoubleClick: () => {
                      this.props.history.push(
                        `/organisations/${record.organisationId}/auth-servers/${record.authServerId}`
                      );
                    },
                  };
                }}
                expandedRowRender={(record) => (
                  <div>
                    <p style={{ margin: 0 }}>
                      {"organisationId: " + record.organisationId}
                    </p>
                    <p style={{ margin: 0 }}>
                      {"description: " + record.description}
                    </p>
                  </div>
                )}
              />
              <div
                onClick={() => this.handleExpand()}
                style={{ paddingTop: "48px", paddingBottom: "24px" }}
              >
                {expanded ? (
                  <div className="link-button">
                    Hide revoked auth servers{" "}
                    <Icon type="up" style={{ fontSize: "80%" }} />
                  </div>
                ) : (
                  <div className="link-button">
                    Expand to see revoked auth servers{" "}
                    <Icon type="down" style={{ fontSize: "80%" }} />
                  </div>
                )}
              </div>
              {expanded &&
                authServers.filter(
                  (authServer) => authServer.status !== "Active"
                ).length > 0 && (
                  <Table
                    scroll={{ x: 800 }}
                    pagination={false}
                    loading={loading}
                    dataSource={authServers.filter(
                      (authServer) => authServer.status !== "Active"
                    )}
                    columns={columns}
                    onRow={(record, rowIndex) => {
                      return {
                        onDoubleClick: () => {
                          this.props.history.push(
                            `/organisations/${record.organisationId}/auth-servers/${record.authServerId}`
                          );
                        },
                      };
                    }}
                    expandedRowRender={(record) => (
                      <div>
                        <p style={{ margin: 0 }}>
                          {"organisationId: " + record.organisationId}
                        </p>
                        <p style={{ margin: 0 }}>
                          {"description: " + record.description}
                        </p>
                      </div>
                    )}
                  />
                )}
            </div>
          )}
        </CentreLarge>
      </div>
    );
  }
}

export default withStore(AuthServers);
