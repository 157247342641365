import { Breadcrumb, Divider, Icon, message, Table, Tag, Tooltip } from "antd";
import moment from "moment";
import React from "react";
import {Link } from "react-router-dom";
import CentreBreadcrumb from "../components/CentreBreadcrumb";
import CentreLarge from "../components/CentreLarge";
import TextLarge from "../components/TextLarge";
import { withStore } from "../components/StoreProvider";
import { makeGet } from "../modules/api-client";


class Organisations extends React.Component {
  state = {
    organisations: [],
    paginationActive: { position: "top" },
    paginationRevoked: { position: "top" },
    loading: true,
    expanded: false,
  };

  componentDidMount() {
    const { search } = this.props.location;

    message.loading("Organisations loading...", 0.5);

    makeGet(`/organisations${ search }`)
      .then((res) => {
        let { paginationActive, paginationRevoked } = this.state;
        let data = res.body.data;
        paginationActive.total = data.filter(
          (organisation) => organisation.status === "Active"
        ).length;
        paginationRevoked.total = data.filter(
          (organisation) => organisation.status === "Revoked"
        ).length;
        this.setState({
          organisations: data,
          paginationActive: paginationActive,
          paginationRevoked: paginationRevoked,
          loading: false,
        });

        if (data.length === 0) {
          message.warning("No organisations found");
        } else {
          message.success("Organisations loaded");
        }
      })
      .catch((err) => {
        this.setState({ organisations: [], loading: false });
        if (err.response.status === 429) {
          message.error("Too many requests");
          this.props.history.push("/");
        } else {
          message.warning("No organisations found");
        }
      });
  }

  componentWillReceiveProps(newProps) {
    const { search } = newProps.location;

    this.setState({ loading: true });

    message.loading("Organisations loading..", 0.5);

    makeGet(`/organisations${ search }`)
      .then((res) => {
        let { paginationActive, paginationRevoked } = this.state;
        let data = res.body.data;
        paginationActive.total = data.filter(
          (organisation) => organisation.status === "Active"
        ).length;
        paginationRevoked.total = data.filter(
          (organisation) => organisation.status === "Revoked"
        ).length;
        this.setState({
          organisations: data,
          paginationActive: paginationActive,
          paginationRevoked: paginationRevoked,
          loading: false,
        });

        if (data.length === 0) {
          message.warning("No organisations found");
        } else {
          message.success("Organisations loaded");
        }
      })
      .catch((err) => {
        this.setState({ organisations: [], loading: false });
        if (err.response.status === 429) {
          message.error("Too many requests");
          this.props.history.push("/");
        } else {
          message.warning("No organisations found");
        }
      });
  }

  handleTableChangeActive = (pagination, filters, sorter) => {
    const pager = { ...this.state.paginationActive };
    pager.current = pagination.current;
    this.setState({ paginationActive: pager });
  };

  handleTableChangeRevoked = (pagination, filters, sorter) => {
    const pager = { ...this.state.paginationRevoked };
    pager.current = pagination.current;
    this.setState({ paginationRevoked: pager });
  };

  handleExpand = () => {
    let { expanded } = this.state;
    this.setState({ expanded: expanded === false });
  };

  render() {
    const { search } = this.props.location;
    const {
      organisations,
      paginationActive,
      paginationRevoked,
      loading,
      expanded,
    } = this.state;
    const { authUser } = this.props.store;

    const columns = [
      {
        title: () => (
          <Tooltip title="Double click on a row to view details">
            Organisation
          </Tooltip>
        ),
        dataIndex: "organisationName",
        key: "organisationName",
        sorter: (a, b) => a.organisationName.localeCompare(b.organisationName),
        defaultSortOrder: "ascend",
        width: "25%",
      },
      {
        title: "Types",
        dataIndex: "",
        key: "types",
        width: "20%",
        render: (record) => (
          <span>
            {record.types.map((type) => (
              <Tag key={type}>{type}</Tag>
            ))}
          </span>
        ),
      },
      { title: "NZBN", dataIndex: "NZBN", key: "NZBN", width: "15%" },
      {
        title: "Created",
        render: (record) => (
          <span>{moment(record.createdDate).format("YYYY-MM-DD")}</span>
        ),
        sorter: (a, b) =>
          moment(a.createdDate).unix() - moment(b.createdDate).unix(),
        width: "15%",
      },
      { title: "Status", dataIndex: "status", key: "status", width: "10%" },
      {
        title: "Actions",
        dataIndex: "",
        key: "x",
        align: "center",
        width: "15%",
        render: (record) => (
          <span>
            <Link to={`/organisations/${record.organisationId}`}>View</Link>
            <Divider type="vertical" />
            { authUser.role === "Admin" && record.organisationId !== authUser.organisationId ? (
              <Link to={`/organisations/${record.organisationId}/edit`}>
                Edit
              </Link>
            ) : (
              <span style={{ color: "rgba(0,0,0,.25)" }}>Edit</span>
            )}
          </span>
        ),
      },
    ];

    return (
      <div>
        <CentreBreadcrumb>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={"/"}>
                Home
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={"/organisations"}> All Organisations </Link>
            </Breadcrumb.Item>
            {search && (
              <Breadcrumb.Item>
                <strong> Searched for: {search.split("?search=")[1]}</strong>
              </Breadcrumb.Item>
            )}
          </Breadcrumb>
        </CentreBreadcrumb>
        <CentreLarge title="Organisations register">
          <TextLarge>
            <p>
              Listed below are all the organisations registered as Service Users with the API Centre.
            </p>
            <p>
              You can click through to view authorisation server and certificate information for other
              organisations, and maintain information for your organisation.
            </p>
          </TextLarge>
          <Table
            data-cy='org-register-table-active-orgs'
            scroll={{ x: 800 }}
            pagination={paginationActive}
            onChange={this.handleTableChangeActive}
            loading={loading}
            dataSource={organisations.filter(
              (organisation) => organisation.status === "Active"
            )}
            columns={columns}
            onRow={(record, rowIndex) => {
              return {
                onDoubleClick: () => {
                  this.props.history.push(
                    `/organisations/${record.organisationId}`
                  );
                },
              };
            }}
            expandedRowRender={(record) => (
              <p style={{ margin: 0 }}>
                {"organisationId: " + record.organisationId}
              </p>
            )}
          />

          <div
            onClick={() => this.handleExpand()}
            style={{ paddingTop: "48px", paddingBottom: "24px" }}
          >
            {expanded ? (
              <div className="link-button">
                Hide revoked organisations{" "}
                <Icon type="up" style={{ fontSize: "80%" }} />
              </div>
            ) : (
              <div className="link-button">
                Expand to see revoked organisations{" "}
                <Icon type="down" style={{ fontSize: "80%" }} />
              </div>
            )}
          </div>

          {expanded &&
            organisations.filter(
              (organisation) => organisation.status !== "Active"
            ).length > 0 && (
              <Table
                data-cy='org-register-table-inactive-orgs'
                scroll={{ x: 800 }}
                pagination={paginationRevoked}
                onChange={this.handleTableChangeRevoked}
                loading={loading}
                dataSource={organisations.filter(
                  (organisation) => organisation.status !== "Active"
                )}
                columns={columns}
                onRow={(record, rowIndex) => {
                  return {
                    onDoubleClick: () => {
                      this.props.history.push(
                        `/organisations/${record.organisationId}`
                      );
                    },
                  };
                }}
                expandedRowRender={(record) => (
                  <p style={{ margin: 0 }}>
                    {"organisationId: " + record.organisationId}
                  </p>
                )}
              />
            )}
        </CentreLarge>
      </div>
    );
  }
}

export default withStore(Organisations);
