import { Button, Icon } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import CentreButton from "./CentreButton";

const ButtonOrganisationEdit = ({ organisationId }) => (
  <CentreButton>
    <Link data-cy="edit-organisation" to={`/organisations/${organisationId}/edit`}>
      <Button className="btn btn--edit">
        Edit Organisation
      </Button>
    </Link>
  </CentreButton>
);

export default ButtonOrganisationEdit;
