import { Button, Form, Input, message } from "antd";
import React from "react";
import CentreSmall from "../components/CentreSmall";
import { makePost } from "../modules/api-client";

class PasswordRequestForm extends React.Component {
  state = {
    submitting: false,
    data: {},
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let { data } = this.state;

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ submitting: true });

        let updatedData = Object.assign({}, data, values);
        this.setState({ data: updatedData });

        makePost(
            `/password/request`,
            { data: updatedData },
        )
        .then(() => {
          message.success("Password reset email sent");
          this.setState({ submitting: false });
          this.props.history.push("/login");
        })
        .catch((err) => {
          this.setState({ submitting: false });
          if (err.response.status === 404) {
            message.error("User does not exist");
          } else {
            message.error("Something went wrong");
          }
        });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { submitting } = this.state;

    return (
      <CentreSmall title="Reset Password">
        <Form onSubmit={this.handleSubmit}>
          <Form.Item>
            {getFieldDecorator("email", {
              rules: [{ required: true, message: "Email cannot be blank" }],
            })(
              <Input data-cy="pw-reset-email" size="default" placeholder="Email" />
            )}
          </Form.Item>

          <Form.Item>
            <Button
                data-cy="pw-reset-submit-btn"
                className="btn"
                type="primary"
                htmlType="submit"
                loading={submitting}
            >
              Reset
            </Button>
          </Form.Item>
        </Form>
      </CentreSmall>
    );
  }
}

const PasswordRequest = Form.create({ name: "passwordRequest" })(
  PasswordRequestForm
);

export default PasswordRequest;