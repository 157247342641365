import { Col, Row } from "antd";
import React from "react";

const CentreMedium = ({ children, title }) => (
  <div className="content">
    <Row gutter={2}>
      <Col xs={2} sm={4} md={5} lg={5} xl={5} />
      <Col xs={20} sm={16} md={14} lg={14} xl={14}>
        <h1 className="h1" style={{ textAlign: "center" }}>
          {title}
        </h1>
        {children}
      </Col>
      <Col xs={2} sm={4} md={5} lg={5} xl={5} />
    </Row>
  </div>
);

export default CentreMedium;
