import { Badge, Breadcrumb, Descriptions, message } from "antd";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import ButtonAuthServerEdit from "../components/ButtonAuthServerEdit";
import CentreBreadcrumb from "../components/CentreBreadcrumb";
import CentreLarge from "../components/CentreLarge";
import TextLarge from "../components/TextLarge";
import { withStore } from "../components/StoreProvider";
import { makeGet } from "../modules/api-client";

class AuthServer extends React.Component {
  state = {
    authServer: {},
    loaded: false,
  };

  componentDidMount() {
    const { organisationId, authServerId } = this.props.match.params;

    message.loading("Auth server loading...", 0.5);

    makeGet(`/organisations/${ organisationId }/auth-servers/${ authServerId }`)
      .then((res) => {
        message.success("Auth server loaded");
        let data = res.body.data;
        this.setState({ authServer: data, loaded: true });
      })
      .catch((err) => {
        if (err.response.status === 404) {
          message.error("Auth server not found");
        } else {
          message.error("User not authorised");
        }
        this.props.history.push(`/organisations/${organisationId}`);
      });
  }

  render() {
    const { authServer, loaded } = this.state;
    const { authUser } = this.props.store;

    return (
      <div>
        <CentreBreadcrumb>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={"/"}>
                Home
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={"/organisations"}> All Organisations </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/organisations/${authServer.organisationId}`}>
                {" "}
                Organisation{" "}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link
                to={`/organisations/${authServer.organisationId}/auth-servers`}
              >
                {" "}
                All Authorisation Servers{" "}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <strong> {authServer.authServerId} </strong>
            </Breadcrumb.Item>
          </Breadcrumb>
        </CentreBreadcrumb>
        {loaded && (
          <CentreLarge title="Authorisation Server">
            {authServer.organisationId === authUser.organisationId ? (
              <ButtonAuthServerEdit
                organisationId={authServer.organisationId}
                authServerId={authServer.authServerId}
              />
            ) : null}
            <TextLarge>
              <p>
                Here are the details for authorisation server{" "}
                {authServer.authServerId}
              </p>
            </TextLarge>
            {authServer.authServerId && (
              <Descriptions bordered>
                <Descriptions.Item label="Auth Server Id" span={3}>
                  {authServer.authServerId}
                </Descriptions.Item>
                <Descriptions.Item label="Organisation Id" span={3}>
                  {authServer.organisationId}
                </Descriptions.Item>
                <Descriptions.Item label="Environment" span={3}>
                  {authServer.environment}
                </Descriptions.Item>
                <Descriptions.Item label="Name" span={3}>
                  {authServer.name}
                </Descriptions.Item>
                <Descriptions.Item label="Description" span={3}>
                  {authServer.description}
                </Descriptions.Item>
                <Descriptions.Item label="Base API URI" span={3}>
                  <a href={authServer.baseAPIURI}>{authServer.baseAPIURI}</a>
                </Descriptions.Item>
                <Descriptions.Item label="Well Known URI" span={3}>
                  <a href={authServer.wellKnownURI}>
                    {authServer.wellKnownURI}
                  </a>
                </Descriptions.Item>
                <Descriptions.Item label="Developer Portal URI" span={3}>
                  <a href={authServer.portalURI}>{authServer.portalURI}</a>
                </Descriptions.Item>
                <Descriptions.Item label="Logo URI" span={3}>
                  <a href={authServer.logoURI}>{authServer.logoURI}</a>
                </Descriptions.Item>
                <Descriptions.Item label="Created Date" span={3}>
                  {moment(authServer.createdDate).format("YYYY-MM-DD HH:mm:ss")}
                </Descriptions.Item>
                <Descriptions.Item label="Status" span={3}>
                  {authServer.status === "Active" ? (
                    <div>
                      {authServer.status} <Badge status="success" />
                    </div>
                  ) : (
                    <div>
                      {authServer.status} <Badge status="error" />
                    </div>
                  )}
                </Descriptions.Item>
              </Descriptions>
            )}
          </CentreLarge>
        )}
      </div>
    );
  }
}

export default withStore(AuthServer);
