import { Col, Row } from "antd";
import React from "react";

const CentreBreadcrumb = ({ children }) => (
  <div className="content">
    <Row gutter={2}>
      <Col xs={0} sm={1} md={1} lg={1} xl={2} />
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        {children}
      </Col>
      <Col xs={0} sm={1} md={1} lg={1} xl={2} />
    </Row>
  </div>
);

export default CentreBreadcrumb;
