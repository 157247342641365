import { makeAutoObservable, action } from 'mobx';
import { makePersistable, clearPersistedStore } from 'mobx-persist-store';

export default class AppStore {
    isHydrated = false;
    authUser = null;

    constructor() {
        if (__DEVELOPMENT) {
            window.Store = this;
        }
        makeAutoObservable(this);
        // persist to prevent logging in again on page reloads / navigations / duplicating tabs etc.
        makePersistable(this, { name: 'AppStore', properties: ['authUser'], storage: window.sessionStorage })
        .then(action((persistStore) => {
            this.isHydrated = true;
        }))
    }

    setAuthUser(user) {
        this.authUser = user;
    }

  async clearStoredData() {
    await clearPersistedStore(this);
  }
}
