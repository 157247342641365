import {Alert, Button, Col, Row} from "antd";
import QueueAnim from "rc-queue-anim";
import React from "react";
import { Link } from "react-router-dom";
import { withStore } from "../components/StoreProvider";

class Home extends React.Component {
  mfa_link = "https://paymentsnzlimited.b2clogin.com/PaymentsNZLimited.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_Sign_Up_API_Connector_PROD&client_id=964152d2-5a54-4959-8ae7-162efa329f50&nonce=defaultNonce&scope=openid&redirect_uri=https%3A%2F%2Fregister.apicentre.co.nz%2Flogin%2Fazuread-b2c-oauth2%2F&response_type=code";

  render() {
    const { authUser } = this.props.store;

    return (
        <div className="banner-content-wrapper">
          <Row>
            <QueueAnim delay={250}>
              <Col key="a" xs={24} sm={24} md={24} lg={10} xl={10}>
                <h1 className="banner__header">
                  <span>Welcome to the API centre register</span>
                </h1>

                { authUser?.status === "Active" || authUser?.status === "Inactive" ? (
                   <Link className="btn banner__btn" to={"/organisations"}>
                    View the register
                  </Link>
                ): (
                    <>

                      <div data-show="true" className="ant-alert ant-alert-info ant-alert-with-description"><i
                          aria-label="icon: info-circle" className="anticon anticon-info-circle ant-alert-icon">
                        <svg viewBox="64 64 896 896" focusable="false" className="" data-icon="info-circle" width="1em"
                             height="1em" fill="currentColor" aria-hidden="true">
                          <path
                              d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
                          <path
                              d="M464 336a48 48 0 1 0 96 0 48 48 0 1 0-96 0zm72 112h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V456c0-4.4-3.6-8-8-8z"></path>
                        </svg>
                      </i>
                        <span
                          className="ant-alert-description">Multi-factor authentication (MFA) is now required when logging in. Please complete the <a style={{color:"#009fdf"}} href={this.mfa_link}>MFA setup process</a></span>
                      </div>

                      <Link
                          style={{"marginTop": "10px"}}
                          className="btn banner__btn"
                          to="/login"
                          data-cy="banner-login-btn"
                      >
                        Log in
                      </Link>
                    </>
                )}
              </Col>
              <Col key="b" xs={0} sm={0} md={0} lg={12} xl={12} offset={1}>
                <img
                    className="homepage-graphic"
                    alt=""
                    src="/static/images/homepage-graphic.png"
                />
              </Col>
            </QueueAnim>
          </Row>
        </div>
    )
  }
}

export default withStore(Home);
