import { Breadcrumb, Divider, Icon, message, Table, Tooltip } from "antd";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import ButtonKeyAdd from "../components/ButtonKeyAdd";
import CentreBreadcrumb from "../components/CentreBreadcrumb";
import CentreLarge from "../components/CentreLarge";
import TextLarge from "../components/TextLarge";
import { withStore } from "../components/StoreProvider";
import { makeGet } from "../modules/api-client";

class Keys extends React.Component {
  state = {
    keys: [],
    paginationActive: { position: "top" },
    paginationRevoked: { position: "top" },
    loading: true,
    expanded: false,
  };

  componentDidMount() {
    const { organisationId } = this.props.match.params;

    message.loading("Keys loading...", 0.5);

    makeGet(`/organisations/${organisationId}/keys`)
      .then((res) => {
        let { paginationActive, paginationRevoked } = this.state;
        let keys = res.body.keys;
        paginationActive.total = keys.filter(
          (key) => key.status === "Active"
        ).length;
        paginationRevoked.total = keys.filter(
          (key) => key.status === "Revoked"
        ).length;
        this.setState({
          keys: keys,
          paginationActive: paginationActive,
          paginationRevoked: paginationRevoked,
          loading: false,
        });
        if (keys.length === 0) {
          message.warning("No keys found");
        } else {
          message.success("Keys loaded");
        }
      })
      .catch(() => {
        this.setState({ keys: [], loading: false });
      });
  }

  handleTableChangeActive = (pagination, filters, sorter) => {
    const pager = { ...this.state.paginationActive };
    pager.current = pagination.current;
    this.setState({ paginationActive: pager });
  };

  handleTableChangeRevoked = (pagination, filters, sorter) => {
    const pager = { ...this.state.paginationRevoked };
    pager.current = pagination.current;
    this.setState({ paginationRevoked: pager });
  };

  handleExpand = () => {
    let { expanded } = this.state;
    this.setState({ expanded: expanded === false });
  };

  render() {
    const { organisationId } = this.props.match.params;
    const {
      keys,
      paginationActive,
      paginationRevoked,
      loading,
      expanded,
    } = this.state;
    const { authUser } = this.props.store;

    const columns = [
      {
        title: () => (
          <Tooltip title="Double click on a row to view details">
            Environment
          </Tooltip>
        ),
        dataIndex: "environment",
        key: "environment",
        sorter: (a, b) => a.environment.localeCompare(b.environment),
        width: "25%",
      },
      { title: "Use", dataIndex: "use", key: "use", width: "15%" },
      { title: "Key Type", dataIndex: "kty", key: "kty", width: "15%" },
      {
        title: "Created",
        render: (record) => (
          <span>{moment(record.createdDate).format("YYYY-MM-DD")}</span>
        ),
        sorter: (a, b) =>
          moment(a.createdDate).unix() - moment(b.createdDate).unix(),
        defaultSortOrder: "descend",
        width: "20%",
      },
      { title: "Status", dataIndex: "status", key: "status", width: "10%" },
      {
        title: "Actions",
        dataIndex: "",
        key: "x",
        align: "center",
        width: "15%",
        render: (record) => (
          <span>
            <Link
              to={`/organisations/${record.organisationId}/keys/${record.kid}`}
            >
              View
            </Link>
            <Divider type="vertical" />
            {authUser.role === "Admin" || record.organisationId === authUser.organisationId ? (
              <Link
                to={`/organisations/${record.organisationId}/keys/${record.kid}/edit`}
              >
                Edit
              </Link>
            ) : (
              <span style={{ color: "rgba(0,0,0,.25)" }}>Edit</span>
            )}
          </span>
        ),
      },
    ];
    return (
      <div>
        <CentreBreadcrumb>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={"/"}>
                Home
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={"/organisations"}> All Organisations </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/organisations/${organisationId}`}>
                {" "}
                Organisation{" "}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <strong> All Keys </strong>
            </Breadcrumb.Item>
          </Breadcrumb>
        </CentreBreadcrumb>
        <CentreLarge title="Keys">
          {organisationId === authUser.organisationId ? (
            <ButtonKeyAdd organisationId={organisationId} />
          ) : null}
          <TextLarge>
            <p>Listed below are all keys for the organisation.</p>
            <p>
              You can click through to view key information, and if authorised,
              maintain the status of the key.
            </p>
          </TextLarge>
          {keys && (
            <div>
              <Table
                scroll={{ x: 800 }}
                pagination={paginationActive}
                onChange={this.handleTableChangeActive}
                loading={loading}
                dataSource={keys.filter((key) => key.status === "Active")}
                columns={columns}
                onRow={(record, rowIndex) => {
                  return {
                    onDoubleClick: () => {
                      this.props.history.push(
                        `/organisations/${record.organisationId}/keys/${record.kid}`
                      );
                    },
                  };
                }}
                expandedRowRender={(record) => (
                  <div>
                    <p style={{ margin: 0 }}>{"kid: " + record.kid}</p>
                    <p style={{ margin: 0 }}>
                      {"organisationId: " + record.organisationId}
                    </p>
                    <p style={{ margin: 0 }}>{"name: " + record.name}</p>
                  </div>
                )}
              />
              <div
                onClick={() => this.handleExpand()}
                style={{ paddingTop: "48px", paddingBottom: "24px" }}
              >
                {expanded ? (
                  <div className="link-button">
                    Hide revoked keys{" "}
                    <Icon type="up" style={{ fontSize: "80%" }} />
                  </div>
                ) : (
                  <div className="link-button">
                    Expand to see revoked keys{" "}
                    <Icon type="down" style={{ fontSize: "80%" }} />
                  </div>
                )}
              </div>
              {expanded &&
                keys.filter((key) => key.status !== "Active").length > 0 && (
                  <Table
                    scroll={{ x: 800 }}
                    pagination={paginationRevoked}
                    onChange={this.handleTableChangeRevoked}
                    loading={loading}
                    dataSource={keys.filter((key) => key.status !== "Active")}
                    columns={columns}
                    onRow={(record, rowIndex) => {
                      return {
                        onDoubleClick: () => {
                          this.props.history.push(
                            `/organisations/${record.organisationId}/keys/${record.kid}`
                          );
                        },
                      };
                    }}
                    expandedRowRender={(record) => (
                      <div>
                        <p style={{ margin: 0 }}>{"kid: " + record.kid}</p>
                        <p style={{ margin: 0 }}>
                          {"organisationId: " + record.organisationId}
                        </p>
                        <p style={{ margin: 0 }}>{"name: " + record.name}</p>
                      </div>
                    )}
                  />
                )}
            </div>
          )}
        </CentreLarge>
      </div>
    );
  }
}

export default withStore(Keys);
