import React from "react";
import { Link, withRouter } from "react-router-dom";
import { withStore } from "../components/StoreProvider";
import {makeGet, makePost} from "../modules/api-client";
import {Alert, message, Row} from "antd";
import CenteredLoader from "../components/CenteredLoader";

class CompleteAzureB2C extends React.Component {
    state = {
        submitting: false,
        loading: true,
        data: {},
        next: "",
        error: false,
        errorMessage: undefined,
        id_token: undefined
      };



      handleLogout = (id_token) => {
        const { store } = this.props;
        // we remove authUser first incase something goes wrong with the logout. it'll force
        // us to recheck our authentication status on the next request.
        store.setAuthUser(null);

          makeGet(`/users/logout-azureb2c/${id_token}`)
          .then((result) => {

            if(result.body.logout_url){
                window.location.href = result.body.logout_url;
            }else{
                console.error("Could not redirect user to azureB2C logout")
            }
          })

      };


    componentDidMount() {
        const { search } = this.props.location;
        const { authUser } = this.props.store;

        const error = new URLSearchParams(this.props.location.search).get("error");
        const error_description = new URLSearchParams(this.props.location.search).get("error_description");

        if(error && error_description){
            this.setState({
                submitting: false,
                error: true,
                loading: false,
                errorMessage: error_description,
            });
            return;
        }

        let next = "/organisations";
    
        if (search) {
          next = search.split("?next=")[1];
        }

        this.setState({ next: next });
    
        // user is already authenticated so no need to login again.
        if (authUser) {
          this.props.history.push(next);
          return;
        }

        const { store } = this.props;

        const code = new URLSearchParams(this.props.location.search).get("code");
        const state = new URLSearchParams(this.props.location.search).get("state");
        
        if(!code ){
            this.setState({error: true, errorMessage: "Code not provided"});
        }
        if(!this.state.error){
            makePost(
                `/users/complete-azureb2c`,
                {state, code},
            )
            .then((result) => {
                message.success("User logged in");
                this.setState({submitting: false});
                store.setAuthUser(result.body.data.user);
                this.props.history.push("/organisations");
                console.log("Logged in")
            })
            .catch((err) => {
                this.setState({
                    submitting: false,
                    error: true,
                    loading: false,
                    errorMessage: err?.response?.body?.data?.message,
                    id_token: err?.response?.body?.data?.id_token,
                });
            });
        }


    }

    render() {
        const { loading } = this.state;

        if(loading){
          return (
              <CenteredLoader/>
          )
        }
        return (
            <>
                {this.state.submitting ? (<p>Loading</p>) : null }
                {this.state.error  ? (
                    <Row style={{height:"80vh"}} type="flex" justify="space-around" align="middle">
                        <Alert
                            message="Error"
                            description={this.state.errorMessage}
                            type="error"
                            showIcon
                        />
                    </Row>
                ) : null }

                {
                    this.state.id_token && (
                        <a className="btn banner__btn" onClick={()=> { this.handleLogout(this.state.id_token) } }
                             data-cy="error-logout-btn"
                        >
                            Log out
                        </a>
                    )
                }
            </>
        );
    }

}

let AzureB2CHandler = CompleteAzureB2C;

AzureB2CHandler = withRouter(AzureB2CHandler);
AzureB2CHandler = withStore(AzureB2CHandler);

export default AzureB2CHandler;
