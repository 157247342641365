import { Button, Icon } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import CentreButton from "./CentreButton";

const ButtonKeyEdit = ({ organisationId, keyId }) => (
  <CentreButton>
    <Link to={`/organisations/${organisationId}/keys/${keyId}/edit`}>
      <Button className="btn btn--edit">
        Edit Key
      </Button>
    </Link>
  </CentreButton>
);

export default ButtonKeyEdit;
