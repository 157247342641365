// eslint-disable max-classes-per-file
import React from 'react';
import pick from 'lodash/pick';
import { observer } from 'mobx-react';

/*
* StoreContext
* Sets up React context Provider & Consumer to House mobx observable store
* */
export const StoreContext = React.createContext(null);
StoreContext.displayName = 'AppStore';

export const StoreProvider = ({ children, store }) => {
    return (
        <StoreContext.Provider value={ store }>
            { children }
        </StoreContext.Provider>
    );
};

/*
* withStore
* Higher order component to pass store to props and wrap component with observer.
* can be passed args to specify which stores are required
* */
export const withStore = (Component) => {
    const WrappedComponent = observer(Component);
    return class extends React.Component {
        render() {
            return (
                <StoreContext.Consumer>
                    { store => (<WrappedComponent store={ store } { ...this.props } />) }
                </StoreContext.Consumer>
            )
        }
    }
};
