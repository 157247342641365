import {Badge, Breadcrumb, Descriptions, message, Row} from "antd";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import ButtonUserEdit from "../components/ButtonUserEdit";
import CentreBreadcrumb from "../components/CentreBreadcrumb";
import CentreLarge from "../components/CentreLarge";
import TextLarge from "../components/TextLarge";
import { withStore } from "../components/StoreProvider";
import { makeGet } from "../modules/api-client";
import ButtonUserChangePassword from "../components/ButtonUserChangePassword";
import CenteredLoader from "../components/CenteredLoader";

class User extends React.Component {
  state = {
    user: {},
    loaded: false,
  };

  componentDidMount() {
    const { organisationId, userId } = this.props.match.params;

    message.loading("User loading...", 0.5);

    makeGet(`/organisations/${ organisationId }/users/${ userId }`)
      .then((res) => {
        message.success("User loaded");
        let data = res.body.data;
        this.setState({ user: data, loaded: true });
      })
      .catch((err) => {
        if (err.response.status === 404) {
          message.error("User not found");
        } else {
          message.error("User not authorised");
        }
        this.props.history.push(`/organisations/${organisationId}`);
      });
  }

  render() {
    const { user, loaded } = this.state;
    const { authUser } = this.props.store;
    const { userId: authUserId  } = authUser;
    return (
      <div>
        <CentreBreadcrumb>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={"/"}>
                Home
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={"/organisations"}> All Organisations </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/organisations/${user.organisationId}`}>
                {" "}
                Organisation{" "}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/organisations/${user.organisationId}/users`}>
                {" "}
                All Users{" "}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <strong> {user.firstName} </strong>
            </Breadcrumb.Item>
          </Breadcrumb>
        </CentreBreadcrumb>

        {!loaded && (
            <CenteredLoader/>
        )}


        {loaded && (
          <CentreLarge title="User">
            {authUser.role === "Admin" || user.organisationId === authUser.organisationId ? (
              <ButtonUserEdit
                organisationId={user.organisationId}
                userId={user.userId}
              />
            ) : null}

            { authUserId === user.userId && <ButtonUserChangePassword/> }
            <TextLarge>
              <p>Here are the details for user {user.firstName}</p>
            </TextLarge>
            {user.userId && (
              <h2 className="h2">{user.firstName + " " + user.lastName}</h2>
            )}
            {user.userId && (
              <Descriptions bordered data-cy="user-info-table">
                <Descriptions.Item label="Name" span={3}>
                  {user.firstName + " " + user.lastName}
                </Descriptions.Item>
                <Descriptions.Item label="Email" span={3}>
                  {user.email}
                </Descriptions.Item>
                <Descriptions.Item label="Created Date" span={3}>
                  {moment(user.createdDate).format("YYYY-MM-DD HH:mm:ss")}
                </Descriptions.Item>
                <Descriptions.Item label="Status" span={3}>
                  {user.status === "Active" ? (
                    <div>
                      {user.status} <Badge status="success" />
                    </div>
                  ) : (
                    <div>
                      {user.status} <Badge status="error" />
                    </div>
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="UserId" span={3}>
                  {user.userId}
                </Descriptions.Item>

              </Descriptions>
            )}
          </CentreLarge>
        )}
      </div>
    );
  }
}

export default withStore(User);
