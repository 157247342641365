import { Breadcrumb, Button, Form, Input, message, Select } from "antd";
import React from "react";
import { Link, withRouter } from "react-router-dom";
import CentreBreadcrumb from "../components/CentreBreadcrumb";
import CentreMedium from "../components/CentreMedium";
import { isValidURI } from "../components/Common";
import TextSmall from "../components/TextSmall";
import { withStore } from "../components/StoreProvider";
import { makePost } from "../modules/api-client";

class AuthServerAddForm extends React.Component {
  state = {
    data: {},
    submitting: false,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { data } = this.state;
    const { organisationId } = this.props.match.params;

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ submitting: true });

        var updatedData = Object.assign({}, data, values);
        for (var key in updatedData) {
          if (
            typeof updatedData[key] === "string" ||
            updatedData[key] instanceof String
          ) {
            updatedData[key] = updatedData[key].toString().trim();
          }
        }
        this.setState({ data: updatedData });

        makePost(
            `/organisations/${ organisationId }/auth-servers`,
            { data: updatedData },
        )
        .then((res) => {
          message.success("Auth server added");
          this.props.history.push(`/organisations/${ organisationId }/auth-servers/${ res.body.data.authServerId }`);
        })
        .catch((err) => {
          this.setState({ submitting: false });
          message.error(`Error: ${ err.response.body.data.error }`);
        });
      }
    });
  };

  validateURI = (rule, value, callback) => {
    if (value === null || value.length === 0) {
      callback();
      return;
    } else if (isValidURI(value)) {
      callback();
      return;
    }
    callback("Must be a valid URI");
  };

  render() {
    const { getFieldDecorator, isFieldTouched } = this.props.form;
    const { organisationId } = this.props.match.params;
    const { submitting } = this.state;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    return (
      <div>
        <CentreBreadcrumb>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={"/"}>
                Home
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={"/organisations"}> All Organisations </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/organisations/${organisationId}`}>
                {" "}
                Organisation{" "}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/organisations/${organisationId}/auth-servers`}>
                {" "}
                All Authorisation Servers{" "}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <strong> Add </strong>
            </Breadcrumb.Item>
          </Breadcrumb>
        </CentreBreadcrumb>
        <CentreMedium title="Authorisation Server (Add)">
          <TextSmall>
            Add an authorisation server for your organisation to the API Centre
            Register.
          </TextSmall>
          <Form onSubmit={this.handleSubmit}>
            <Form.Item {...formItemLayout} label="Environment">
              {getFieldDecorator("environment", {
                rules: [
                  { required: true, message: "Environment cannot be blank" },
                ],
              })(
                <Select
                    data-cy="auth-server-env"
                    placeholder="Auth server environment"
                    disabled={submitting}
                >
                  <Select.Option data-cy="auth-server-env-non-production" value="Non-Production">
                    Non-Production
                  </Select.Option>
                  <Select.Option value="Production">Production</Select.Option>
                </Select>
              )}
            </Form.Item>
            <Form.Item {...formItemLayout} label="Name">
              {getFieldDecorator("name", {
                rules: [{ required: true, message: "Name cannot be blank" }],
              })(<Input
                  data-cy="auth-server-name"
                  placeholder="Auth server name"
                  disabled={submitting}
              />)}
            </Form.Item>
            <Form.Item {...formItemLayout} label="Description">
              {getFieldDecorator("description", {
                initialValue: "",
                rules: [{ required: false }],
              })(<Input
                  data-cy="auth-server-description"
                  disabled={submitting}
                  placeholder="Auth server description"
              />)}
            </Form.Item>
            <Form.Item {...formItemLayout} label="Base API URI">
              {getFieldDecorator("baseAPIURI", {
                initialValue: "",
                rules: [{ required: false }, { validator: this.validateURI }],
              })(<Input
                  data-cy="auth-server-base-api-uri"
                  disabled={submitting}
                  placeholder="Auth server base API URI"
              />)}
            </Form.Item>
            <Form.Item {...formItemLayout} label="Well Known URI">
              {getFieldDecorator("wellKnownURI", {
                initialValue: "",
                rules: [{ required: false }, { validator: this.validateURI }],
              })(<Input
                  data-cy="auth-server-well-known-uri"
                  disabled={submitting}
                  placeholder="Auth server well known URI"
              />)}
            </Form.Item>
            <Form.Item {...formItemLayout} label="Developer Portal URI">
              {getFieldDecorator("portalURI", {
                initialValue: "",
                rules: [{ required: false }, { validator: this.validateURI }],
              })(<Input
                  data-cy="auth-server-portal-uri"
                  placeholder="Auth server portal URI"
                  disabled={submitting}
              />)}
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="Logo URI"
              validateStatus="success"
            >
              {getFieldDecorator("logoURI", {
                initialValue: "",
                rules: [{ required: false }, { validator: this.validateURI }],
              })(<Input
                  data-cy="auth-server-logo-uri"
                  placeholder="Auth server logo URI"
                  disabled={submitting}
              />)}
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
              <Button
                data-cy="auth-server-add-submit-btn"
                className="btn btn--edit"
                type="primary"
                htmlType="submit"
                loading={submitting}
                disabled={
                  !(
                    isFieldTouched("environment") ||
                    isFieldTouched("name") ||
                    isFieldTouched("description") ||
                    isFieldTouched("baseAPIURI") ||
                    isFieldTouched("logoURI") ||
                    isFieldTouched("portalURI") ||
                    isFieldTouched("wellKnownURI")
                  )
                }
              >
                Add
              </Button>
            </Form.Item>
          </Form>
        </CentreMedium>
      </div>
    );
  }
}

let AuthServerAdd = Form.create({ name: "authServerAdd" })(AuthServerAddForm);

AuthServerAdd = withRouter(AuthServerAdd);
AuthServerAdd = withStore(AuthServerAdd);

export default AuthServerAdd;
