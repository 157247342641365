import { Button, Icon } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import CentreButton from "./CentreButton";

const ButtonAuthServerEdit = ({ organisationId, authServerId }) => (
  <CentreButton>
    <Link
      to={`/organisations/${organisationId}/auth-servers/${authServerId}/edit`}
    >
      <Button className="btn btn--edit">
        Edit Auth Server
      </Button>
    </Link>
  </CentreButton>
);

export default ButtonAuthServerEdit;
