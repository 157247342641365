import { Breadcrumb, Button, Checkbox, Form, Input, message } from "antd";
import React from "react";
import { Link, withRouter } from "react-router-dom";
import CentreBreadcrumb from "../components/CentreBreadcrumb";
import TextSmall from "../components/TextSmall";
import CentreLarge from "../components/CentreLarge";
import { withStore } from "../components/StoreProvider";
import { makePost } from "../modules/api-client";

class OrganisationAddForm extends React.Component {
  state = {
    data: {},
    submitting: false,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let { data } = this.state;

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ submitting: true });

        var updatedData = Object.assign({}, data, values);
        for (var key in updatedData) {
          if (
            typeof updatedData[key] === "string" ||
            updatedData[key] instanceof String
          ) {
            updatedData[key] = updatedData[key].toString().trim();
          }
        }
        this.setState({ data: updatedData });

        makePost(
            '/organisations',
            { data: updatedData },
        )
        .then((res) => {
          message.success("Organisation added");
          this.props.history.push(`/organisations/${ res.body.data.organisationId }`);
        })
        .catch((err) => {
          this.setState({ submitting: false });
          message.error(`Error: ${ err.response.body.data.error }`);
        });
      }
    });
  };

  render() {
    const { getFieldDecorator, isFieldTouched } = this.props.form;
    const { submitting } = this.state;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 9 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24
        },
        sm: {
          span: 16
        },
      },
    };

    return (
      <div>
        <CentreBreadcrumb>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={"/"}>
                Home
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={"/organisations"}> All Organisations </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <strong> Add </strong>
            </Breadcrumb.Item>
          </Breadcrumb>
        </CentreBreadcrumb>
        <CentreLarge title="Organisation (Add)">
          <TextSmall>
            <p>Add an organisation to the API Centre Register.</p>
          </TextSmall>
          <Form onSubmit={this.handleSubmit} style={{ textAlign: "left" }}>
            <Form.Item {...formItemLayout} label="Organisation Name">
              {getFieldDecorator("organisationName", {
                rules: [
                  {
                    required: true,
                    message: "Organisation name cannot be blank",
                  },
                ],
              })(<Input data-cy="org-name" placeholder="Organisation name" />)}
            </Form.Item>
            <Form.Item {...formItemLayout} label="NZBN">
              {getFieldDecorator("NZBN", {
                rules: [{ required: true, message: "NZBN cannot be blank" }],
              })(<Input data-cy="org-nzbn" placeholder="Organisation NZBN" />)}
            </Form.Item>
            <Form.Item {...formItemLayout} label="Types">
              {getFieldDecorator("types", {
                rules: [{ required: true, message: "Types cannot be blank" }],
              })(
                <Checkbox.Group placeholder="Organisation types">
                  <Checkbox value="API Provider">API Provider</Checkbox>
                  <Checkbox data-cy="org-type-third-party" value="Third Party">Third Party</Checkbox>
                </Checkbox.Group>
              )}
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
              <Button
                data-cy="org-add-submit-btn"
                className="btn"
                type="primary"
                htmlType="submit"
                loading={submitting}
                disabled={
                  !(
                    isFieldTouched("organisationName") ||
                    isFieldTouched("NZBN") ||
                    isFieldTouched("types")
                  )
                }
              >
                Add
              </Button>
            </Form.Item>
          </Form>
        </CentreLarge>
      </div>
    );
  }
}

let OrganisationAdd = Form.create({ name: "organisationAdd" })(OrganisationAddForm);

OrganisationAdd = withRouter(OrganisationAdd);
OrganisationAdd = withStore(OrganisationAdd);

export default OrganisationAdd;
